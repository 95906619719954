/**
 * Soubor s mixiny používanými v celé aplikaci
 * @author Jan Preuss
 */

// Pozicovani pozadi prvku
.background-origin (@origin: padding-box) {
    -webkit-background-origin: @origin;
    -moz-background-origin: @origin;
    background-origin: @origin;
}

// Zakulacené rohy
.border-radius (@radius: 5px) {
    .border-radius-top(@radius);
    .border-radius-bottom(@radius);
}

.border-radius-top(@radius: 5px) {
    .border-radius-top-left(@radius);
    .border-radius-top-right(@radius);
}

.border-radius-bottom(@radius: 5px) {
    .border-radius-bottom-left(@radius);
    .border-radius-bottom-right(@radius);
}

.border-radius-right(@radius: 5px) {
    .border-radius-top-right(@radius);
    .border-radius-bottom-right(@radius);
}

.border-radius-left(@radius: 5px) {
    .border-radius-top-left(@radius);
    .border-radius-bottom-top(@radius);
}

.border-radius-top-left(@radius: 5px) {
    -moz-border-radius-topleft: @radius;
    -webkit-border-top-left-radius: @radius;
    -khtml-border-top-left-radius: @radius;
    border-top-left-radius: @radius;
}

.border-radius-top-right(@radius: 5px) {
    -moz-border-radius-topright: @radius;
    -webkit-border-top-right-radius: @radius;
    -khtml-border-top-right-radius: @radius;
    border-top-right-radius: @radius;
}

.border-radius-bottom-left(@radius: 5px) {
    -moz-border-radius-bottomleft: @radius;
    -webkit-border-bottom-left-radius: @radius;
    -khtml-border-bottom-left-radius: @radius;
    border-bottom-left-radius: @radius;
}

.border-radius-bottom-right(@radius: 5px) {
    -moz-border-radius-bottomright: @radius;
    -webkit-border-bottom-right-radius: @radius;
    -khtml-border-bottom-right-radius: @radius;
    border-bottom-right-radius: @radius;
}

//
//  Nastaví neděděnou průhlednost prvku
//  @params color    - barva pozadí prvku (napr #00ff00)
//          alpha    - pruhlednost 0-1 (napr 0.5)
//          alphahex - alpha*255 prevedeno na hexadecimalni cislo
.trans(@color, @alpha) {
    background: @color;
    background: hsla(hue(@color), saturation(@color), lightness(@color), @alpha);
    //    @hcolor: ~`"@{color}".substring(1)`;
    //    filter: ~`"progid:DXImageTransform.Microsoft.gradient(startColorstr=#@{alphahex}@{hcolor}, endColorstr=#@{alphahex}@{hcolor})"`;
    //    -ms-filter: ~'"progid:DXImageTransform.Microsoft.gradient(startColorstr=#@{alphahex}@{hcolor}, endColorstr=#@{alphahex}@{hcolor})"';
}

//nastaveni ikonky z mrizky do pozadi
.icon(@width, @x, @y) {
    width: ~"@{width}px";
    height: ~"@{width}px";
    @left: @width * (@x - 1);
    @top: @width * (@y - 1);
    background-position: ~"-@{left}px" ~"-@{top}px";
}

//nastaveni nepravidelne ikonky z mrizky do pozadi
.unicon(@width, @height, @x, @y) {
    width: ~"@{width}px";
    height: ~"@{height}px";
    @left: @width * (@x - 1);
    @top: @height * (@y - 1);
    background-position: ~"-@{left}px" ~"-@{top}px";
}

//vytvori ikonky pro vsechny velikosti
//  @params name    -jmeno css bloku pro ktery se ikony vytvari
//          x       -poradi v X-ove souradnici
//          y       -poradi v Y-ove souradnici
.seticons(@name, @x, @y) {
    .@{name}-16 {
        background-image: url("../images/ikony_16x16.png");
        .icon(16, @x, @y)
    }
    .@{name}-24 {
        background-image: url("../images/ikony_24x24.png");
        .icon(24, @x, @y)
    }
}

//nastavuje stin objektu
.shadow(@horizontal, @vertical, @blur, @spread, @color) {
    -webkit-box-shadow: @horizontal @vertical @blur @spread @color;
    box-shadow: @horizontal @vertical @blur @spread @color;
}

//zvyrazneni objektu
.highlight(@color) {
    .shadow(0px, 0px, 5px, 1px, @color);
}

.arrow-box {
    position: relative;
}

.arrow-up-v, .arrow-up-u, .arrow-down-v, .arrow-down-u, .arrow-right-v, .arrow-right-u, .arrow-left-v, .arrow-left-u {
    height: 0;
    width: 0;
    position: absolute;
}

.arrow-up-v {
    border-color: transparent;
    border-bottom-color: #0089D7;
    border-style: dashed dashed solid dashed;
    border-width: 0 8.5px 8.5px 8.5px;
}

.arrow-up-u {
    border-color: transparent;
    border-bottom-color: #fff;
    border-style: dashed dashed solid dashed;
    border-width: 0 8.5px 8.5px 8.5px;
}

.arrow-down-v {
    border-color: transparent;
    border-top-color: #0089D7;
    border-style: solid dashed dashed dashed;
    border-width: 8.5px 8.5px 0 8.5px;
}

.arrow-down-u {
    border-color: transparent;
    border-top-color: #fff;
    border-style: solid dashed dashed dashed;
    border-width: 8.5px 8.5px 0 8.5px;
}

.arrow-right-v {
    border-color: transparent;
    border-left-color: #0089D7;
    border-style: dashed dashed dashed solid;
    border-width: 8.5px 0 8.5px 8.5px;
}

.arrow-right-u {
    border-color: transparent;
    border-left-color: #fff;
    border-style: dashed dashed dashed solid;
    border-width: 8.5px 0 8.5px 8.5px;
}

.arrow-left-v {
    border-color: transparent;
    border-right-color: #0089D7;
    border-style: dashed solid dashed dashed;
    border-width: 8.5px 8.5px 8.5px 0;
}

.arrow-left-u {
    border-color: transparent;
    border-right-color: #fff;
    border-style: dashed solid dashed dashed;
    border-width: 8.5px 8.5px 8.5px 0;
}