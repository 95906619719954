/**
 * Konstantni polozky
 * @author Petr Fotijev
 */

/**
 * Klíčová slova: error, description, disabled, info, success
 */


.logo {
    background-image: url("../images/logo_adaxet.png");
    width: 89px;
    height: 34px;
}

@settings-minheight: 350px;

@slogan-color: #244d59;

@menu-hover-highlight: #29a2e1;

@tables-title-color: #009dff;

@error-highlight: red;
@warning-highlight: orange;

@form-items-height: 18px;
@form-items-highlight: #29a2e1;

@nprogress-color: #0089D7;

.form-items{
    .border-radius(3px);
    border: 1px solid #7e929e;
    background-image: url("../images/input_bg.png");
    background-repeat: repeat-x;
    font-family: Calibri, Calib, Arial, Helvetica, sans-serif;
}

.form-items-disabled{
    background-image: url("../images/input_bg_disabled.png");
}

.form-items-required {
    background-image: url("../images/input_bg_yellow.png");
}

.shadowpanel(){
    .shadow(5px, 8px, 4px, 2px, rgba(00, 00, 00, 0.14));
}

.aleft{text-align: left;}
.aright{text-align: right;}
.fleft{float: left;}
.fright{float: right;}
.inline{display: inline-block;}
.centered {text-align: center;}
.bold {font-weight: bold;}
.iheight input {height: 20px;}

@history-background: #F5F8FB;
@history-border: #ffffff;
@history-name-card-color: #505050;
@history-option-color: #056EC7;
@history-option-color-temp: #96BCDC;
@history-option-border: #BCCCD5;
@history-option-border-hover: #66D3FC;