#list {
    .trans(#ffffff,0.8);
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff, endColorstr=#ccffffff);
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff, endColorstr=#ccffffff)";
    width: 960px;
    .border-radius(4px);
    margin-left: auto;
    margin-right: auto;
    .shadowpanel();

    .list-filter {
        .border-radius-top(4px);
        background-image: url("../images/vypis_filtr.png");
        background-repeat: repeat-x;
        background-color: #8ecff3;
        padding: 0 0 10px 0;
        color: white;

        .form-submit {
            background-image: url("../images/tlacitko_filtr_32x90.png");
            background-position: left;
            width: 90px;
            height: 32px;
            color: #ffffff;
            font-weight: bold;
            font-size: 13px;
            border-color: #0b7ee0;
            padding: 0;
        }

        .list-filter-dot {
            margin: 10px 0 0 15px;
            float: left;
            background-image: url("../images/ikony_12x12.png");
            border: 1px solid #70cfe8;
            .border-radius(3px);
            .icon(12,1,1);
        }

        .list-filter-name {
            font-weight: bold;
            color: #fff;
            float: left;
            margin: 8px 0 0 12px;
        }

        .list-filter-switch {
            color: #88CAFF;
            float: left;
            margin: 8px 0 0 12px;
            a {
                color: #88CAFF;
                &.active, &:hover {
                    color: #fff;
                }
                span {
                    color: #88CAFF;
                }
            }
        }

        .list-filter-count {
            float: right;
            color: #fff;
            padding: 9px;
        }

        label{
            overflow: hidden;
            height: 20px;
        }
    }

    .list-paging {
        color: #fff;
        height: 28px;
        line-height: 28px;
        background-image: url("../images/vypis_strankovani_bg.png");
        background-repeat: repeat-x;
        width: 100%;
        margin-left: auto;
        margin-right: auto;
        text-align: center;

        a {
            color: #fff;
            &:hover {
                color: #fff;
                text-decoration: underline;
            }
        }

        .list-prev {
            margin-right: 25px;
        }

        .list-next {
            margin: 0 25px;
        }

        input {
            width: 43px;
            height: 14px;
        }

        input[type=submit] {
            width: 18px;
            height: 18px;
            background-image: url("../images/vypis_strankovani_tlacitko.png");
            background-repeat: no-repeat;
            border: none;
        }

        form {
            display: inline-block;
        }
    }

    .list-paging-pages {
        display: inline-block;
    }


    .list-paging-icons {
        float: right;
        height: 28px;
    }
    #list-paging-add{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-print{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-wizard{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-refresh{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-increase{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-discount{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-send{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-link{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-calculator{
        margin: 1px 5px 0 0;
        display: inline-block;
    }
}

#list h2 {
    color: @tables-title-color;
    font-size: 12px;
    margin-top: 0px;
    margin-bottom: 5px;
    font-weight: normal;
    padding-left: 5px;
    line-height: 25px;
}

.list-sum {
    background-image: url("../images/vypis_sum_bg.png");
    background-repeat: repeat-x;
    height: 25px;

}
.list-sum-visible{
    background: #b7e3ff;
    padding-right: 20px;
    //.trans(#b7e3ff,0.9);
}
.list-sum-tab {
    float: right;
    border: none;
    margin: 0 5px 10px;
    border-collapse: collapse;
    color: #505050;
    font-size: 11px;
}
.list-sum-tab th {
    padding-left: 15px;
    color: #37bbfd;
    border-bottom: 1px solid #37bbfd;
    font-weight: bold;
    text-align: left;
    vertical-align: bottom;
}
.list-sum-tab th:first-child {
    padding-left: 1px;
}
.list-sum-tab td{
    padding-right: 3px;
}

.list-sum-tab td:last-child{
    width: 110px;
}

.list-sum-tab td.right, .list-sum-tab th.right{
    text-align: right;
}

.list-sum-tab td.left{
    text-align: left;
}

.sum-celkem {
    border-top: 1px solid #37bbfd;
    font-weight: bold;
}
.sum-celkem td:first-child{
    text-align: left;
}
.sum-celkem td{
    padding-right: 0px;
}
#sum-print{
    margin: 1px 0px 1px 0;
    float: right;
}