#menu-pictures{
    background-image: url("../images/menu_pic_bg.png");
    background-repeat: repeat-x;
    height: 59px;
    border-bottom: 1px solid #17a1f1;
}
#menu-pictures ul{
    margin: 0;
    padding: 0;
}
#menu-pictures ul li{
    border: 1px solid #67bae5;
    font-size: 11px;
    margin: 3px -3px 0 0px;
    height: 52px;
    text-align: center;
    list-style-type: none;
    display: inline-block;
    .border-radius(3px);
}
#menu-pictures ul li a{
    padding-top: 32px; display: block; height: 21px; width: 60px;
}
#menu-pictures ul li.active a{
    color: white;
}
#menu-pictures ul li.disabled{
    border: 1px solid #bfbfbf;
}
#menu-pictures ul li.disabled a{
    color: #5d6366;
    cursor: default;
}
.groupfirst {
    margin-left: 3px !important;
}

#menu-pictures ul li.active{
    .border-radius-top(3px) !important;
    .border-radius-bottom(0px);
    border-top: 1px solid #17a1f1 !important;
    border-left: 1px solid #17a1f1 !important;
    border-right: 1px solid #17a1f1 !important;
    height: 55px !important;
    border-bottom: 1px solid #b7e5fc !important;
}