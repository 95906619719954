
/* ikony 16x16*/
.icon-16 {
    @size: 16;

    background-image: url("../images/ikony_@{size}x@{size}.png");

    &.disabled      { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    &:disabled      { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }

    &.empty         { .icon(@size,17, 8); }
    &.calendar      { .icon(@size, 4, 5); }
    &.add           { .icon(@size, 1, 1); }
    &.new           { .icon(@size, 2, 1); }
    &.delete        { .icon(@size, 3, 1); }
    &.orderUp       { .icon(@size,15, 4); }
    &.orderDown     { .icon(@size,18, 4); }
    &.restore       { .icon(@size, 4, 1); }
    &.excel         { .icon(@size, 5, 1); }
    &.edit          { .icon(@size, 6, 1); }
    &.open          { .icon(@size, 6, 1); }
    &.refresh       { .icon(@size, 7, 1); }
    &.print         { .icon(@size, 8, 1); }
    &.send          { .icon(@size, 9, 1); }
    &.help          { .icon(@size,11, 1); }
    &.history       { .icon(@size,11, 1); }
    &.unlock        { .icon(@size,12, 1); }
    &.lock          { .icon(@size,13, 1); }
    &.cross         { .icon(@size,15, 1); }
    &.offerurgency  { .icon(@size, 1, 4); }
    &.offer         { .icon(@size, 2, 4); }
    &.done          { .icon(@size, 3, 4); }
    &.billing       { .icon(@size, 4, 4); }
    &.inprogress    { .icon(@size, 5, 4); }
    &.copy          { .icon(@size, 1, 5); }
    &.paste         { .icon(@size, 2, 2); }
    &.rename        { .icon(@size, 3,10); }
    &.langcs        { .icon(@size, 7, 9); }
    &.langen        { .icon(@size, 8, 9); }
    &.langfr        { .icon(@size, 9, 9); }
    &.langde        { .icon(@size,10, 9); }
    &.key           { .icon(@size, 5, 9); }
    &.false         { .icon(@size,14, 9); }
    &.merror        { .icon(@size,15, 9); }
    &.msuccess      { .icon(@size,17, 9); }
    &.minfo         { .icon(@size,16, 9); }
    &.photogallery  { .icon(@size,16, 1); }
    &.increase      { .icon(@size,17, 1); }
    &.discount      { .icon(@size,17, 1); }
    &.exchange_rate { .icon(@size,18, 1); }
    &.right         { .icon(@size,17, 8); }
    &.left          { .icon(@size,18, 8); }
    &.up            { .icon(@size,18, 9); }
    &.down          { .icon(@size, 1,10); }
    &.up_blue       { .icon(@size,15, 4); }
    &.down_blue     { .icon(@size,18, 4); }
    &.dsWord        { .icon(@size, 1, 6); }
    &.dsWord.locked { .icon(@size, 3, 6); }
    &.dsExcel       { .icon(@size, 6, 8); }
    &.dsExcel.locked    { .icon(@size, 8, 8); }
    &.dsText        { .icon(@size, 16,7); }
    &.dsText.locked { .icon(@size, 18,7); }
    &.dsFolder      { .icon(@size, 16,6); }
    &.dsPdf         { .icon(@size, 13,6); }
    &.dsPdf.locked  { .icon(@size, 15,6); }
    &.dsUnknown     { .icon(@size, 16,7); }
    &.dsUnknown.locked  { .icon(@size, 18,7); }
    &.dsXml         { .icon(@size, 9, 8); }
    &.dsXml.locked  { .icon(@size, 11,8); }
    &.history       { .icon(@size, 11,1); }
    &.wizard        { .icon(@size, 7, 5); }
    &.move          { .icon(@size, 1,16); }
    &.updown        { .icon(@size, 3,16); cursor: move; }
    &.info          { .icon(@size, 8, 5); cursor: pointer; }

    /**
     * STAVY
     **/
    &.state_empty            { .icon(@size,17, 8); }
    &.state_folder           { .icon(@size, 9, 5); }
    &.state_folder_valid     { .icon(@size,10, 5); }
    &.state_folder_lock      { .icon(@size,11, 5); }
    &.state_folder_invalid   { .icon(@size,12, 5); }
    &.state_folder_alert     { .icon(@size,13, 5); }
    &.state_folder_1         { .icon(@size,14, 5); }
    &.state_folder_forward   { .icon(@size,15, 5); }
    &.state_folder_deny      { .icon(@size,16, 5); }
    &.state_folder_open      { .icon(@size,16, 6); }
    &.state_folder_open_lock { .icon(@size,18, 6); }
    &.state_folder_red       { .icon(@size, 1, 7); }
    &.state_folder_red_lock  { .icon(@size, 3, 7); }

    &.state_folder_blue      { .icon(@size,17, 5); }
    &.state_folder_blue_coin { .icon(@size,18, 5); }
    &.state_folder_delete    { .icon(@size, 9, 5); }
    &.state_folder_coin      { .icon(@size,14,15); }
    &.state_folder_key       { .icon(@size,15,15); }
    &.state_folder_settings  { .icon(@size,16,15); }
    &.state_folder_go        { .icon(@size,17,15); }
    &.state_folder_edit      { .icon(@size,18,15); }

    &.state_a                { .icon(@size, 3,13); }
    &.state_b                { .icon(@size, 4,13); }
    &.state_c                { .icon(@size, 5,13); }
    &.state_d                { .icon(@size, 6,13); }
    &.state_e                { .icon(@size, 7,13); }
    &.state_f                { .icon(@size, 8,13); }
    &.state_g                { .icon(@size, 9,13); }
    &.state_g                { .icon(@size,10,13); }
    &.state_i                { .icon(@size,11,13); }
    &.state_j                { .icon(@size,12,13); }
    &.state_k                { .icon(@size,13,13); }
    &.state_l                { .icon(@size,14,13); }
    &.state_m                { .icon(@size,15,13); }
    &.state_n                { .icon(@size,16,13); }
    &.state_o                { .icon(@size,17,13); }
    &.state_p                { .icon(@size,18,13); }
    &.state_q                { .icon(@size,17,13); }
    &.state_r                { .icon(@size, 1,14); }
    &.state_s                { .icon(@size, 2,14); }
    &.state_t                { .icon(@size, 3,14); }
    &.state_u                { .icon(@size, 4,14); }
    &.state_v                { .icon(@size, 5,14); }
    &.state_w                { .icon(@size, 6,14); }
    &.state_x                { .icon(@size, 7,14); }
    &.state_y                { .icon(@size, 8,14); }
    &.state_z                { .icon(@size, 9,14); }

    &.state_1                { .icon(@size,10,14); }
    &.state_2                { .icon(@size,11,14); }
    &.state_3                { .icon(@size,12,14); }
    &.state_4                { .icon(@size,13,14); }
    &.state_5                { .icon(@size,14,14); }
    &.state_6                { .icon(@size,15,14); }
    &.state_7                { .icon(@size,16,14); }
    &.state_8                { .icon(@size,17,14); }
    &.state_9                { .icon(@size,18,14); }

    &.state_red_shout2       { .icon(@size, 1,15); }
    &.state_red_shout3       { .icon(@size, 2,15); }
    &.state_red_shout1       { .icon(@size, 3,15); }
    &.state_blue_shout2      { .icon(@size, 4,15); }
    &.state_blue_shout3      { .icon(@size, 5,15); }
    &.state_blue_shout1      { .icon(@size, 6,15); }
    &.state_person_inc       { .icon(@size, 7,15); }
    &.state_person           { .icon(@size, 8,15); }
    &.state_person_out       { .icon(@size, 9,15); }
    &.state_person_coin      { .icon(@size,10,15); }
    &.state_person_orange    { .icon(@size,11,15); }
    &.state_person_deny      { .icon(@size,12,15); }
    &.state_folder_deny2     { .icon(@size,13,15); }

    &.state_red              { .icon(@size, 9,12); }
    &.state_orange           { .icon(@size,10,12); }
    &.state_green            { .icon(@size,11,12); }
    &.state_grey             { .icon(@size,12,12); }

    &.state_earth            { .icon(@size, 7,12); }
    &.state_doc_settings     { .icon(@size,13,12); }
    &.state_settings_allow   { .icon(@size,14,12); }
    &.state_edit_deny        { .icon(@size,15,12); }
    &.state_home             { .icon(@size,16,12); }
    &.state_pen              { .icon(@size,17,12); }
    &.state_document_edit    { .icon(@size,18,12); }

    &.state_hammer           { .icon(@size, 4,10); }
    &.state_hammer_no        { .icon(@size, 5,10); }
    &.state_case             { .icon(@size, 6,10); }
    &.state_dolar            { .icon(@size, 7,10); }
    &.state_coin             { .icon(@size, 8,10); }
    &.state_coin_allow       { .icon(@size, 9,10); }
    &.state_coin_deny        { .icon(@size,10,10); }
    &.state_coin_forbiden    { .icon(@size,11,10); }
    &.state_calendar         { .icon(@size,12,10); }
    &.state_calendar_coin    { .icon(@size,13,10); }
    &.state_hand_coin        { .icon(@size,14,10); }
    &.state_doc_allow        { .icon(@size,15,10); }
    &.state_doc_deny         { .icon(@size,16,10); }
    &.state_doc_fwd          { .icon(@size,17,10); }
    &.state_doc_coin         { .icon(@size,18,10); }

    &.state_doc_inc          { .icon(@size, 1,11); }
    &.state_doc_front        { .icon(@size, 2,11); }
    &.state_doc_copy         { .icon(@size, 3,11); }
    &.state_doc_save         { .icon(@size, 4,11); }
    &.state_doc_add          { .icon(@size, 5,11); }
    &.state_heart            { .icon(@size, 6,11); }
    &.state_key              { .icon(@size, 7,11); }
    &.state_star             { .icon(@size, 8,11); }
    &.state_lock             { .icon(@size, 9,11); }
    &.state_cart             { .icon(@size,10,11); }
    &.state_cart_allow       { .icon(@size,11,11); }
    &.state_cart_deny        { .icon(@size,12,11); }
    &.state_cart_forbiden    { .icon(@size,13,11); }
    &.state_cart_coin        { .icon(@size,14,11); }
    &.state_cart_save        { .icon(@size,15,11); }
    &.state_transport        { .icon(@size,16,11); }
    &.state_transport_full   { .icon(@size,17,11); }
    &.state_transport_save   { .icon(@size,18,11); }
    &.state_transport_rem    { .icon(@size, 1,12); }
}


/*ikony 18x18*/
.icon-18 {
    @size: 18;

    background-image: url("../images/ikony_@{size}x@{size}.png") !important;

    &.disabled  { background-image: url("../images/ikony_@{size}x@{size}_disabled.png") !important; }
    &:disabled  { background-image: url("../images/ikony_@{size}x@{size}_disabled.png") !important; }

    &.down      { .icon(@size, 1, 1); }
    &.right     { .icon(@size, 2, 1); }
    &.up        { .icon(@size, 3, 1); }
    &.left      { .icon(@size, 4, 1); }
    &.calendar  { .icon(@size, 5, 1); }
    &.checked   { .icon(@size, 6, 1); }
    &.unchecked { .icon(@size, 7, 1); }
    &.cross     { .icon(@size, 8, 1); }
    &.range     { .icon(@size, 9, 1); }
    &.edit      { .icon(@size,10, 1); }
    &.add       { .icon(@size,11, 1); }
    &.search    { .icon(@size,12, 1); }
    &.dsearch   { .icon(@size,13, 1); }
    &.remove    { .icon(@size,14, 1); }
    &.upload    { .icon(@size,15, 1); }
    &.download  { .icon(@size,16, 1); }
    &.clock     { .icon(@size,17, 1); }
    &.key       { .icon(@size,18, 1); }
    &.refresh   { .icon(@size,19, 1); }
    &.variable  { .icon(@size,22, 1); }
}

.icon-20-checkbox {
    @size: 20;

    background-image: url("../images/ikony_@{size}x@{size}-checkbox.png");

    //&.disabled       { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    //&:disabled       { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    &.hidden         { display: none; }
    &.horizontal     { margin: 1px 0 0 0; display: inline-block; }

    &.checked        { .icon(@size, 1, 1); }
    &.unchecked      { .icon(@size, 2, 1); }
}

/*ikony 24x24*/
.icon-24 {
    @size: 24;

    background-image: url("../images/ikony_@{size}x@{size}.png");

    &.disabled              { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    &:disabled              { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    &.hidden                { display: none; }
    &.horizontal            { margin: 1px 0 0 0; display: inline-block; }

    &.add                   { .icon(@size, 1, 1); }
    &.new                   { .icon(@size, 2, 1); }
    &.home                  { .icon(@size,15, 5); }
    &.delete                { .icon(@size, 3, 1); }
    &.restore               { .icon(@size, 4, 1); }
    &.excel                 { .icon(@size, 5, 1); }
    &.edit                  { .icon(@size, 6, 1); }
    &.refresh               { .icon(@size, 7, 1); }
    &.print                 { .icon(@size, 8, 1); }
    &.send                  { .icon(@size, 9, 1); }
    &.serial_numbers        { .icon(@size, 9, 4); }
    &.help                  { .icon(@size,11, 1); }
    &.unlock                { .icon(@size,12, 1); }
    &.lock                  { .icon(@size,13, 1); }
    &.link                  { .icon(@size,14, 1); }
    &.exit                  { .icon(@size,15, 1); }
    &.photogallery          { .icon(@size,16, 1); }
    &.increase              { .icon(@size,17, 1); }
    &.discount              { .icon(@size,17, 1); }
    &.exchange_rate         { .icon(@size,18, 1); }
    &.generate_doc          { .icon(@size,13, 5); }
    &.generate_doc_transfer { .icon(@size,14, 5); }
    &.up                    { .icon(@size, 5, 4); }
    &.arrow_up              { .icon(@size, 1, 4); }
    &.arrow_down            { .icon(@size, 2, 4); }
    &.wizard                { .icon(@size, 6, 1); }
    &.default               { .icon(@size,11, 4); }
    &.calculator            { .icon(@size, 1, 6); }
    &.graph                 { .icon(@size, 2, 6); }
    &.group_modification    { .icon(@size, 3, 6); }
    &.group_edit            { .icon(@size, 4, 6); }
    &.group_write           { .icon(@size, 5, 6); }
    &.make_partner          { .icon(@size, 13, 4); }

}


/* ikony 26x26*/
.icon-26 {
    @size: 26;

    background-image: url("../images/ikony_@{size}x@{size}.png");

    &.active                { background-image: url("../images/ikony_@{size}x@{size}_active.png"); }
    &.hover:hover           { background-image: url("../images/ikony_@{size}x@{size}_active.png"); }
    &.disabled              { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    &.disabled.hover:hover  { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }
    &:disabled              { background-image: url("../images/ikony_@{size}x@{size}_disabled.png"); }

    &.flag      { .icon(@size, 1, 1); }
    &.user      { .icon(@size, 2, 1); }
    &.calendar  { .icon(@size, 3, 1); }
    &.is        { .icon(@size, 4, 1); }
    &.help      { .icon(@size, 5, 1); }
    &.logout    { .icon(@size, 6, 1); }
    &.exit      { .icon(@size, 7, 1); }
    &.langcs    { .icon(@size, 8, 1); }
    &.langen    { .icon(@size, 9, 1); }
    &.langfr    { .icon(@size,10, 1); }
    &.langde    { .icon(@size,11, 1); }
    &.notes     { .icon(@size,12, 1); }
}


/*ikony menu 60x55*/
.icon-60x55 {
    @sizeX: 60;
    @sizeY: 55;

    background-image: url("../images/ikony_@{sizeX}x@{sizeY}.png");

    &.active                { background-image: url("../images/ikony_@{sizeX}x@{sizeY}_active.png"); }
    &.hover:hover           { background-image: url("../images/ikony_@{sizeX}x@{sizeY}_active.png"); }
    &.disabled              { background-image: url("../images/ikony_@{sizeX}x@{sizeY}_disabled.png"); }
    &.disabled.hover:hover  { background-image: url("../images/ikony_@{sizeX}x@{sizeY}_disabled.png"); }
    &:disabled              { background-image: url("../images/ikony_@{sizeX}x@{sizeY}_disabled.png"); }

    &.general           { .unicon(@sizeX, @sizeY, 1, 1); }
    &.parties           { .unicon(@sizeX, @sizeY, 2, 1); }
    &.prices            { .unicon(@sizeX, @sizeY, 3, 1); }
    &.process           { .unicon(@sizeX, @sizeY, 4, 1); }
    &.acts              { .unicon(@sizeX, @sizeY, 5, 1); }
    &.post              { .unicon(@sizeX, @sizeY, 1, 2); }
    &.transportation    { .unicon(@sizeX, @sizeY, 2, 2); }
    &.costs             { .unicon(@sizeX, @sizeY, 3, 2); }
    &.tasks             { .unicon(@sizeX, @sizeY, 4, 2); }
    &.data              { .unicon(@sizeX, @sizeY, 5, 2); }
    &.debt              { .unicon(@sizeX, @sizeY, 1, 3); }
    &.comments          { .unicon(@sizeX, @sizeY, 2, 3); }
    &.backup            { .unicon(@sizeX, @sizeY, 3, 3); }
    &.billing           { .unicon(@sizeX, @sizeY, 4, 3); }
    &.calc              { .unicon(@sizeX, @sizeY, 5, 3); }
    &.pictures          { .unicon(@sizeX, @sizeY, 6, 1); }
    &.rights            { .unicon(@sizeX, @sizeY, 7, 1); }
    &.sampler           { .unicon(@sizeX, @sizeY, 6, 2); }
    &.palette           { .unicon(@sizeX, @sizeY, 6, 3); }
    &.settings          { .unicon(@sizeX, @sizeY, 7, 2); }
    &.series            { .unicon(@sizeX, @sizeY, 7, 3); }
    &.texts             { .unicon(@sizeX, @sizeY, 1, 2); }
}

