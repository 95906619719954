/**
 * vztahy ikon a jejich obalů ovlivňující pozadí, bordery a hovery
 * @author Petr Fotijev
*/

input.icon-16, input.icon-18, input.icon-24, input.icon-26, input.icon-60x55 {
    .background-origin(border-box);
}

/* Bordry a pozadi pro ikony */
.iconb-form-option {
    border: 1px solid #007CD2;
    .border-radius(3px);
}
.iconb-disabled {
    border: 1px solid #afc1d1;
    .border-radius(3px);
}
.iconb-state {
    background-color: white;
    .border-radius(3px);
}

.iconb-grid-button {
    background-color: white;
    border: #afc1d1 1px solid;
    .border-radius(3px);
}

/* Hovery pro ikony */
.blue-hover:hover {
    .highlight(@form-items-highlight);
}