/**
 * Nastaveni jednotlivych menu dlazdic
 * @author Petr Fotijev
*/

#menu-zakazky {
    .trans(#0060c0,0.6);
}
#menu-zakazky.menu-items-disabled{
    .trans(#8d969b,0.4);
}
#menu-zakazky-pozadi {
    background-image: url("../images/ikony_136x142.png");
    background-repeat: no-repeat;
    .unicon(136,142,2,1);  
}
.menu-items-disabled #menu-zakazky-pozadi {
    background-image: url("../images/ikony_136x142_disabled.png");
    background-repeat: no-repeat;
    .unicon(136,142,2,1); 
}
.menu-contracts-titles {font-weight: bold;}


#menu-adresar {
    .trans(#0080ff,0.5);
}
#menu-adresar.menu-items-disabled {
    .trans(#8d969b,0.4);
}
#menu-adresar-pozadi {
    background-image: url("../images/ikony_136x142.png");
    background-repeat: no-repeat;
    .unicon(136,142,5,1); 
}
.menu-items-disabled #menu-adresar-pozadi {
    background-image: url("../images/ikony_136x142_disabled.png");
    background-repeat: no-repeat;
    .unicon(136,142,5,1); 
}

#menu-statistika{
    .trans(#0080ff,0.3);
}
#menu-statistika.menu-items-disabled {
    .trans(#8d969b,0.4);
}
#menu-statistika-pozadi {
    background-image: url("../images/ikony_136x142.png");
    background-repeat: no-repeat;
    .unicon(136,142,1,3);
}
.menu-items-disabled #menu-statistika-pozadi {
    background-image: url("../images/ikony_136x142_disabled.png");
    background-repeat: no-repeat;
    .unicon(136,142,1,3); 
}


#menu-nastaveni {
    .trans(#0059c8,0.1);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#190059c8, endColorstr=#190059c8);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#190059c8, endColorstr=#190059c8)";
}
#menu-nastaveni.menu-items-disabled {
    .trans(#8d969b,0.4);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#190059c8, endColorstr=#190059c8);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#190059c8, endColorstr=#190059c8)";
}
#menu-nastaveni-pozadi {
    background-image: url("../images/ikony_136x142.png");
    background-repeat: no-repeat;
    .unicon(136,142,2,3); 
}
.menu-items-disabled #menu-nastaveni-pozadi {
    background-image: url("../images/ikony_136x142_disabled.png");
    background-repeat: no-repeat;
    .unicon(136,142,2,3); 
}
#menu-nastaveni.menu-items .menu-title a {color: #505050;}