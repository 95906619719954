/**
 * Základní styly aplikace
 * @author Jan Preuss
 */

html,body{
	margin:0;
	padding:0;
	height:100%;
}

body {
	font: 16px/1.5 "Trebuchet MS", "Geneva CE", lucida, sans-serif;
	color: #333;
	background-color: #fff;
}

h1 {
	font-size: 150%;
	color: #3484D2;
}

table {
    border-spacing: 0px;
    border-collapse: collapse;
}

#ajax-spinner {
	margin: 15px 0 0 15px;
	padding: 13px;
	background: white url('../images/spinner.gif') no-repeat 50% 50%;
	font-size: 0;
	z-index: 123456;
	display: none;
}

div.flash {
	color: black;
	background: #FFF9D7;
	border: 1px solid #E2C822;
	padding: 1em;
	margin: 1em 0;
}

a[href^="error:"] {
	background: red;
	color: white;
}

.identity-container {
    text-align: right;
}

.menu-container {
    float: left;
}

.copyright{
	position:absolute;
	bottom:0;
	width:100%;
	height:60px;   /* Height of the footer */
	text-align: right;
	font-size: 14px;
	color: #141430;
	& > div{
		padding-top: 10px;

	}
}

.page-wrap{
	min-height:100%;
	position:relative;
}

div.content-wrap{
	padding-bottom:60px;
}