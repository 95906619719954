.ui-dialog {
    padding: 0px;
}


.ui-dialog .ui-dialog-content {
    padding: 0px;
}

.ui-widget-header {
    border: 0;
    .border-radius-bottom(0px);
}


.ui-dialog .ui-dialog-content .option-group {
    border: 0;
    width: auto;
    margin: auto;
    background: transparent;
    padding: 20px 0 0 20px;
}


.ui-dialog .ui-dialog-content .groupButtons {
    border: 0;
    width: auto;
    margin: 18px auto 0 auto;
    background: url("../images/formular_tlacitka.png") repeat-x center;
    padding: 5px;
    text-align: center;
    .border-radius-bottom(4px);
}


.ui-dialog .pair-margin-bottom-1 {
    margin-bottom: 14px !important;
}

.ui-dialog .pair-margin-bottom-2 {
    margin-bottom: 28px !important;
}

.ui-dialog .pair-margin-bottom-3 {
    margin-bottom: 42px !important;
}

.ui-dialog .pair-margin-bottom-4 {
    margin-bottom: 56px !important;
}

.ui-dialog .pair-margin-top-1 {
    margin-top: 14px !important;
}

.ui-dialog .pair-margin-top-2 {
    margin-top: 28px !important;
}

.ui-dialog .pair-margin-top-3 {
    margin-top: 42px !important;
}

.ui-dialog .pair-margin-top-4 {
    margin-top: 56px !important;
}


.ui-dialog {
    .progress-bar {
        margin: 30px;

        .ui-progressbar-value {
            margin: 0px;
        }

        .progress-label {
            float: left;
            width: 100%;
            text-align: center;
            margin-top: 5px;
            font-weight: bold;
            color: #FFFFFF;
            text-shadow: 1px 1px 0 #666666;
        }
    }
}