
.datastorage-explorer {

    .head {

        .breadcrumb {
            .border-radius(3px);
            border: 1px solid #7e929e;
            background: #FFFFFF url("../images/input_bg.png") repeat-x;
            color: #000000;
            padding: 1px 6px;
            width: 390px;

            a {
                color: #000000;
            }

        }

    }

}