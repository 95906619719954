#content-menu {

    position: relative;

    ul {
        color: #505050;
        margin: 0 0 0 0px;;
        padding: 0px 0 0 0px;
        background-repeat: repeat-x;
        display: none;
        position: absolute;
        left: 0;
        height: 29px;
        width: 100%;

        li {
            margin:2px 0 0 2px;
            list-style-type: none;
            float: left;
            height: 26px;
            
            a {
                line-height: 27px;
                font-size: 12px;
                display: block;
                padding: 0 10px 0 10px;
            }

            &.active {
                
                color: #0077d1;
                background-repeat: repeat-x;
                border-top: 1px solid #86cdf9;
                border-left: 1px solid #86cdf9;
                border-right: 1px solid #86cdf9;
                border-bottom: 1px solid #d6f2ff;
                .border-radius-top(3px);
                
                & > a{
                    font-weight: bold;
                    color: #0077d1;
                }
            }
        }

        &.level0 {
            top: 0px;
            background-image: url("../images/content_menu1_bg.png");
            border-bottom: 1px solid #7CC8F7;
            
            li.active {
                background-image: url("../images/content_menu1_active.png");
                
            }
        }

        &.level1, &.level2, &.level3 {
            height: 29px;
            top: 30px;
            background-image: url("../images/content_menu2_bg.png");
            border-bottom: 1px solid #7CC8F7;

            li.active {
                background-image: url("../images/content_menu2_active.png");
                border-bottom: 1px solid #FFFFFF;
                
            }
        }
    }
}