
.datastorage-explorer {

    .contextmenu {
        position: absolute;
        display: none;
        background: #CCCCCC;
        color: #000000;
        text-align: left;
        border: 1px solid #AAAAAA;
        z-index: 2000;
        min-width: 150px;

        .item {
            clear: both;
            line-height: 24px;
            padding: 0 20px 0 4px;
            cursor: pointer;
            white-space: nowrap;

            &:hover {
                background: #EEEEEE;
            }

            .icon-16 {
                margin: 4px 4px 0 0;
                float: left;
                background-color: #FFFFFF;
            }

            &.disabled, &.disabled:hover {
                color: #999999;
                background-color: #CCCCCC;

                .icon-16 {
                    opacity: 0.75;
                }
            }

        }
    }
}