// nastylovani jednotlivych sloupecku
.dataGrid {

    .datagrid-column-label {
        text-align: right;
    }

    .datagrid-column-number {
        text-align: right;
    }

    .datagrid-column-email {
        text-align: center;
    }

    .datagrid-column-center {
        text-align: center;
    }

    .datagrid-column-right {
        text-align: right;
    }

    .datagrid-column-left {
        text-align: left;
    }

    .valign-top {
        vertical-align: top;
    }

    .datagrid-column-img {
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .datagrid-column-red {
        color: red;
    }

}

// obecne nastylovani datagridu
.dataGrid {
    border-collapse: collapse;
    border: none;
    font-size: 11px;
    table-layout: fixed;

    td {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    td.notruncated {
        white-space: normal;
    }

    &.disabled {
        th {
            color: #6D6D6D !important;
            background-image: url("../images/grid_th_bg_disabled.png");
            a {
                color: #6D6D6D !important;
            }
        }
        td {
            color: #6D6D6D !important;
        }
        .dataGrid-body-row-odd:hover {
            .trans(#E8E8E8, 0.9);
        }
        .dataGrid-body-row-even:hover {
            .trans(#FFFFFF, 0.9);
        }
    }

    th {
        height: 30px;
        color: #00AEFF;
        border: none;
        text-align: left;
        background-color: #B5E2FF;
        background-image: url("../images/grid_th_bg.png");
        background-repeat: repeat-x;
        padding: 0 5px;
        line-height: 11px;
        a {
            color: #00AEFF;
            border: none;
            &:hover {
                text-decoration: underline;
            }
        }
        .ico-arrow {
            margin-top: 4px;
            margin-left: 3px;
        }
    }

    .dataGrid-buttons-header a, .dataGrid-buttons-footer a {
        float: right;
        padding-left: 5px;
    }

    td {
        color: #505050;
        border: none;
        padding: 0 5px;
    }

    tr {
        height: 24px;
        line-height: 24px;
    }

    tr.calculation {
        display: none;

        & > td {
            padding: 0;
            table.dataGrid {
                margin-bottom: 22px;
                th {
                    //background-color: #D0D0D0;
                    background-image: none;
                    color: #7C7C7C;
                    height: 10px;
                    font-size: 10px;
                    line-height: 10px;

                }
                td {
                    color: #7C7C7C;
                    height: 10px;
                    font-size: 10px;
                    line-height: 10px;
                }
            }
        }
    }

    .dataGrid-body-row-odd {
        .trans(#E8E8E8, 0.9);
        &:hover {
            .trans(#BBDFF8, 0.9);
        }
    }

    .dataGrid-body-row-even {
        .trans(#FFFFFF, 0.9);
        &:hover {
            .trans(#BBDFF8, 0.9);
        }
    }

    .dataGrid-body-row-red {
        &.dataGrid-body-row-odd {
            background: rgba(255, 0, 0, 0.3);
            &:hover {
                background: rgba(255, 0, 0, 0.6);
            }
        }

        &.dataGrid-body-row-even {
            background: rgba(255, 0, 0, 0.5);
            &:hover {
                background: rgba(255, 0, 0, 0.6);
            }
        }
    }

    .dataGrid-body-row-orange {
        &.dataGrid-body-row-odd {
            background: rgba(255, 165, 0, 0.3);
            &:hover {
                background: rgba(255, 165, 0, 0.6);
            }
        }

        &.dataGrid-body-row-even {
            background: rgba(255, 165, 0, 0.5);
            &:hover {
                background: rgba(255, 165, 0, 0.6);
            }
        }
    }

    .dataGrid-body-row-yellow {
        &.dataGrid-body-row-odd {
            background: rgba(255, 255, 0, 0.3);
            &:hover {
                background: rgba(255, 255, 0, 0.6);
            }
        }

        &.dataGrid-body-row-even {
            background: rgba(255, 255, 0, 0.5);
            &:hover {
                background: rgba(255, 255, 0, 0.6);
            }
        }
    }

    .dataGrid-body-row-green {
        &.dataGrid-body-row-odd {
            background: rgba(0, 255, 0, 0.3);
            &:hover {
                background: rgba(0, 255, 0, 0.6);
            }
        }

        &.dataGrid-body-row-even {
            background: rgba(0, 255, 0, 0.5);
            &:hover {
                background: rgba(0, 255, 0, 0.6);
            }
        }
    }

    .dataGrid-body-row-blue {
        &.dataGrid-body-row-odd {
            background: rgba(0, 0, 255, 0.3);
            &:hover {
                background: rgba(0, 0, 255, 0.6);
            }
        }

        &.dataGrid-body-row-even {
            background: rgba(0, 0, 255, 0.5);
            &:hover {
                background: rgba(0, 0, 255, 0.6);
            }
        }
    }

    .dataGrid-body-no-rows {
        .trans(#E8E8E8, 0.9);
        td {
            text-align: center;
            opacity: 0.6;
        }
    }

    .selected {
        .trans(#54BDF7, 0.9);
    }

    .dataGrid-body-button {
        margin-left: 4px;
        float: left;
        &.hidden {
            display: none;
        }
    }

    .dataGrid-buttons-td {
        //vertical-align: top;
        padding: 0 0 0 5px;
    }

    .dataGrid-body-preview {
        .preview {
            display: none;
            position: absolute;
            z-index: 10;

            .arrow-up-v, .arrow-up-u {
                left: 5px;
            }
            .arrow-up-v {
                border-bottom-color: #7E929E;
            }
            .arrow-up-u {
                top: 1px;
                z-index: 2;
            }

            .text {
                padding: 10px;
                border: 1px solid #7E929E;
                border-radius: 3px;
                padding: 10px;
                position: relative;
                top: 8px;
                background-color: white;
            }
        }
    }

    .dataGrid-body-row {
        &.selected {
            .dataGrid-body-preview {
                .preview {
                    display: block;
                }
            }
        }
        &.activeRow {
            background-color: #82CFFD;
        }
    }

    .datagrid-sortable-placeholder {
        border: 2px dashed #CCCCCC;
        line-height: 0px;
    }
}
