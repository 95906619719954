/**
 * Zakladni styl zatimni
 * @author Petr Fotijev
*/

@font-face {
    font-family: 'Calib';
    src: url('../css/Calibri.ttf') format('truetype');
}

body {
    font-family: Calibri,Calib, Arial, Helvetica, sans-serif;
    font-size: 12px;
    color: #000;
    margin: 0px;
    padding: 0px;
    background-color: #fff;
    background-attachment: fixed;
    background-image: url("../images/adaxet_pozadi.jpg");
    background-position: center top;
    background-repeat: no-repeat;
    //overflow-y: scroll;   v chrome blblo drag&drop

    &.application-loading {
        cursor: progress !important;
    }
}

a:link {
    text-decoration: none;
    color: #000;
}
a:hover {
    text-decoration: none;
    color: #000;
}
a:visited {
    text-decoration: none;
    color: #000;
}
a img {
    border: none;
}

.margin-auto {
    margin: auto;
}

#top-strip {
    height: 49px;
    background-image: url("../images/horni_pruh.png");
    background-repeat: repeat-x;
}
#nav {
    width: 955px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 20px 0 25px;
}
.nav-marker {
    float: left;
    background-image: url("../images/zalozka_menu_hlavicka.png");
    background-position: 0 0;
    height: 37px;
    width: 69px;
    position: relative;
    top: 7px;
    left: -14px;
}
.nav-marker:hover {
    background-position: 69px 0;
    height: 37px;
    width: 69px;
    position: relative;
    top: 7px;
    left: -14px;
    cursor: pointer;
}
#nav-marker-menu {
    position: relative;
    top: 10px;
    left: 20px;
    background-image: url("../images/ikony_bile_26x26.png");
    .icon(26,1,1);
}
#nav-marker-home {
    position: relative;
    top: 10px;
    left: 20px;
    background-image: url("../images/ikony_bile_26x26.png");
    .icon(26,2,1);
}

.nav-item {
    height: 28px;
    float: right;

}
.nav-item-icon {
    float: left;
    width: 26px;
    height: 26px;
}
.nav-item-text {
    font-size: 12px;
    margin: 0 15px 0 5px;
    float: left;
    line-height: 28px;
}
#nav-logout{
    margin-left: 0px;
}
#nav-help{
    margin-left: 0px;
}
#nav-is{
    margin-left: 0px;
}

#nav-flag{
    margin-right: 10px;
}

#nav-flag-text{
    color: #b1b1b1;
    margin: 4px;
    text-align: center;
    background: white;
    min-width: 20px;
    .border-radius(10px);
    line-height: 20px;
}

#eventpanel {
    z-index: 100;
    position: absolute;
    max-width: 250px;
    top: -10px;
    left: 660px;
    border: 1px solid #326db0;
    color: white;
    .border-radius(4px);
    .shadowpanel();
}
#eventpanel-name {
    .border-radius-top(3px);
    padding: 0 0 0 10px;
    font-size: 12px;
    line-height: 30px;
    font-weight: bold;
    background-image: url("../images/eventpanel_name.png");
    background-repeat: repeat-x;
    height: 30px;
}

#eventpanel-exit {
    margin: 1px 4px 0 0;
    float: right;

    &:hover{
        background-image: url("../images/ikony_26x26.png");
    .icon(26,14,1);
    }
}

.eventpanel-item {
    padding: 0 20px 0 20px;
    font-size: 11px;
    line-height: 24px;
    background-image: url("../images/eventpanel_item.png");
    background-repeat: repeat-x;
    height: 24px;
}

.eventpanel-item:last-child{
    height: 23px;
    .border-radius-bottom(3px);
}

#logos {
    position: relative;
    top: -10px;
    width: 950px;
    margin-left: auto;
    margin-right: auto;
    padding: 0 5px 0 5px;
}

.content-title {
    float: left;
    width: 800px;
    font-size: 28px;
}

/* Flash message */
.message-box {
    display: none;
    position: absolute;
    z-index: 100;
    left: 280px;
    top: -12px;
    line-height: 30px;
    height: 30px;
    width: 500px;
    margin-top:5px;
    border: 1px solid #5db5e3;
    background: #b3e6ff url(../images/message_box_bg_blue.png) 50% 50% repeat-x;
    color: #074f8d;
    font-size: 16px;
    .border-radius(6px);
    .shadowpanel();

    &.info {
        border: 1px solid #6dca0c; // #5db5e3;
        background: #b4e61e url(../images/message_box_bg_green.png) 50% 50% repeat-x; // #b3e6ff url(../images/message_box_bg_blue.png) 50% 50% repeat-x;
        color: #086600; // #074f8d;

        .message-box-icon {
            background-image: url("../images/ikony_16x16.png");
            .icon(16,17,9); // .icon(16,16,9);
        }
    }

    &.success {
        border: 1px solid #6dca0c;
        background: #b4e61e url(../images/message_box_bg_green.png) 50% 50% repeat-x;
        color: #086600;

        .message-box-icon {
            background-image: url("../images/ikony_16x16.png");
            .icon(16,17,9);
        }
    }

    &.error, &.warning {
        border: 1px solid #fd8e6d;
        background: #fad1b2 url(../images/message_box_bg_red.png) 50% 50% repeat-x;
        color: #cd0a0a;

        .message-box-icon {
            background-image: url("../images/ikony_16x16.png");
            .icon(16,15,9);
        }
    }

    .message-box-icon {
        float: left;
        margin: 7px 12px 0 12px;
        background-image: url("../images/ikony_16x16.png");
        .icon(16,16,9);
    }

    .message-box-content {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
    }
}


#company-logo {
    float: right;
    margin: 0px 0 0 50px;
}
#company-slogan {
    float: right;
    margin: 9px 0 0 27px;
    color: @slogan-color;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
}

#main {
    position: relative;
    top: -10px;
    width: 1000px;
    margin-left: auto;
    margin-right: auto;
}

#menu {
    z-index: 5;
    width: 960px;
    margin: 20px 20px 0 20px;
}
.menu-items {
    .border-radius(3px);
    margin: 0 5px 13px 5px;
    height: 142px;
}

.menu-items:hover{
    .shadow(0px, 0px, 3px, 3px, @menu-hover-highlight);
    cursor: pointer;
}
.menu-ticon{
    float: right;
}

.menu-title {
    position: relative;
    font-size: 14px;
    font-family: Tahoma;
    font-weight: normal;
    text-align: center;
    top: -30px;
    margin: 0 !important;
}
.menu-items .menu-title a {
    color: #ffffff;
}
.menu-items-disabled .menu-title a {
    color: #5d6366 !important;
}
.menu-content {
    position: absolute;
    overflow: hidden;
    margin: 0 !important;
    color: #fff;
    padding: 15px 10px 15px 10px;
}
.menu-items-disabled .menu-content{
    color: #5d6366 !important;
}

.menu-dlazdice-leva {
    float: left;
    height: 142px;
    width: 7px;
    background-image: url("../images/menu_leva.png");
    background-repeat: no-repeat;
}
.menu-dlazdice-stred {
    float: left;
    height: 142px;
    background-image: url("../images/menu_stred.png");
    background-repeat: repeat-x;
}
.menu-dlazdice-prava {
    float: left;
    height: 142px;
    width: 7px;
    background-image: url("../images/menu_prava.png");
    background-repeat: no-repeat;
}


.footer {
    margin-left: auto;
    margin-right: auto;

    font-size: 12px;
    color: #505050;
    width: 950px;
    padding: 0 5px 0 5px;

    div {
        width: 420px;
        float: left;
    }
    .footer-pages {
        width: 100%;
        text-align: center;
        display: none;
    }
    .footer-links {
        float: right;
        text-align: right;
    }
    a {
        text-decoration: none;
        color: #505050;

        &:link {
            text-decoration: none;
            color: #505050;
        }
        &:hover {
            text-decoration: none;
            color: #505050;
            text-decoration: underline;
        }
        &:visited {
            text-decoration: none;
            color: #505050;
        }
    }
}

/*vypisy a formulare*/

/*plovouci sipka*/
.arrowmess{
    z-index: 2000;
    position: absolute;
    display: none;

    .arrowmess-arrow{
        z-index: 500;
        background-image: url("../images/sipky_dialogy_12x18.png");
        position: absolute;
        top: 6px;
        left: 0px;
        .unicon(12,18,2,1);
    }

    .arrowmess-body{
        position: absolute;
        border: 1px solid #b0bc75;
        min-height: 22px;
        overflow: hidden;
        padding: 6px 10px 3px 10px;
        left: 8px;
        .trans(#ebffdb,0.9);
        .border-radius(3px);
    }

    &.error {
        .arrowmess-arrow{
            .unicon(12,18,1,1);
        }
        .arrowmess-body{
            border: 1px solid #c9a27e;
            .trans(#fff0de,0.9);
        }
    }

    &.description {
        .arrowmess-arrow{
            .unicon(12,18,3,1);
        }
        .arrowmess-body{
            border: 1px solid #91a2ac;
            .trans(#e7f6ff,0.9);
        }
    }
}

/* Obsah */
.content {
    width: 960px;
    margin-left: auto;
    margin-right: auto;
    .border-radius(4px);
    .shadowpanel();
}
#content-header {
    color: #f3f9ff;
    height: 24px;
    background-image: url("../images/formular_nazev.png");
    background-repeat: repeat-x;
    .border-radius-top(4px);
    padding: 4px 40px 0 15px;

    #content-header-buttons{
        float: right;
        display: inline;
    }

    h1 {
        font-weight: bold;
        font-size: 12px;
        line-height: 24px;
        padding: 0;
        margin: 0;
        color: white;
        font-size: 12px;
        display: inline;
    }
}

#content-center {
    width: 958px;
    border-right: 1px solid #bbe4ff;
    border-left: 1px solid #bbe4ff;
}

.formular-obsah {
    background: #fff;
    background: rgba(255,255,255,0.8);
    /* For IE 5.5 - 7*/
    filter: progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff, endColorstr=#ccffffff);
    /* For IE 8*/
    -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#ccffffff, endColorstr=#ccffffff)";
    padding: 15px 0px 40px 0px;
    background-image: url("../images/formular_obsah_pozadi.png");
    background-repeat: repeat-x;
    background-position: bottom;

    p {
        line-height: 20px;
    }
    p label {
        width: 100px;
        float: left;
    }
    h2 {
        color: @tables-title-color;
        font-size: 12px;
        margin-bottom: 5px;
        font-weight: normal;
        padding-left: 5px;
    }
    table th {
        background-image: url("../images/formular_tabulka_zahlavy.png");
        background-repeat: repeat-x;
    }
}

.calculation-products-quick-insert-bg {
    padding: 5px 0px;
    height: 45px;
    background-color: #D2F0FF;
}

.calculation-products-summarization-bg {
    padding: 5px 0px;
    height: 110px;
    background-color: #D2F0FF;
}

.calculation-products-summarization-hr {
    border-bottom: 1px solid black;
}

.calculation-products-deposit-of-price, .calculation-deposit-of-price {
    input {
        margin: 0px !important;
        float: right !important;
    }
}

.calculation-summarization-bg {
    padding: 5px 0px;
    background-color: #D2F0FF;
}

#content-footer {
    clear: both;
    text-align: right;
    height: 20px;
    padding: 5px 40px;
    .border-radius-bottom(4px);
    background-image: url("../images/formular_tlacitka.png");
    background-repeat: repeat-x;
    background-position: center;
}


.error-highlight, .error-highlight:hover, .error-highlight:focus {
    .shadow(0px, 0px, 3px, 1px, @error-highlight);
}
.warning-highlight, .warning-highlight:hover, .warning-highlight:focus {
    .shadow(0px, 0px, 3px, 1px, @warning-highlight);
}

label, div.label {
    line-height: @form-items-height+2;
}
.pair label, .pair div.label {
    padding-bottom: 0px;
}
input {
    padding: 0;
    height: @form-items-height;
    line-height: @form-items-height;
    .form-items;
}
select {
    height: @form-items-height+2;
    line-height: @form-items-height;
    .form-items;
}
input:required, select:required{
    .form-items-required;
}
input:disabled, select:disabled{
    .form-items-disabled;
}
textarea {
    .form-items;
    resize: vertical;
    min-height: 90px;
    background-color: white;
}
textarea:disabled{
    .form-items-disabled;
    background-color: white;
}

input[type=button]:hover,
input[type=submit]:hover,
input:focus,
select:focus,
textarea:focus {
    .highlight(@form-items-highlight);
}

input[type=button].disabled:hover,
input[type=button]:disabled:hover,
input[type=submit].disabled:hover,
input[type=submit]:disabled:hover,
input.disabled:focus,
input:disabled:focus,
select.disabled:focus,
select:disabled:focus,
textarea.disabled:focus,
textarea:disabled:focus {
    .shadow(0px, 0px, 0px, 0px, @form-items-highlight);
}

a:disabled,
a.disabled {
    pointer-events: none;
}

.pair {
    display: inline;
    margin-bottom: 2px;
}

.hidden {
    display: none !important;
    visibility: hidden !important;
}

.onlyPrint {
    border: 0;
    background: transparent;
    color: #000000;
    margin-top: 1px;
    margin-bottom: 1px;
    box-shadow: none !important;
}
.onlyPrint:disabled {
    border: 0;
    background: transparent;
    color: #000000;
    margin-top: 1px;
    margin-bottom: 1px;
}

.readonly {
    color: GrayText !important;
    background-image: url("../images/input_bg_disabled.png") !important;
    .shadow(0px, 0px, 0px, 0px, #5d6366) !important;
    pointer-events: none !important;
}

.minheight-70 {
    min-height: 70px;
}

.products-image-name-description{
    h4{
        margin: 0px; padding: 0px;
    }
    p{
        line-height: 18px;
    }
}

.clip100 {
    overflow: hidden;
    white-space: nowrap;
    width: 100px;
}

.clip630 {
    overflow: hidden;
    white-space: nowrap;
    width: 630px;
    float: left;
}

#graph-container {
    background-color: rgba(255, 255, 255, 0.9);
}

.border {
    border: 1px solid #7e929e;
    border-radius: 3px;
}