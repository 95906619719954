/* ====== TreeViewer ====== */

.tree-viewer-container {

    width: 860px;

    .node-list {
        text-align: left;
    }

    .node {
        text-align: left;
        font-weight: bold;
        cursor: pointer;
    }

    .node-prefix {
        float: left;
        text-align: left;
    }

    .node-group-hidden {
        display: none;
    }

    .node-radios {
        text-align: right;
        float: right;
    }

    .node-row {
        text-align: left;
        height: 20px;
        &:hover {
            .trans(#bbdff8,0.9);
        }
    }

    .tree-radio {
        margin-left: 20px;
        margin-right: 20px;
    }


    .tree-viewer-rights-col-names {
        text-align: right;
    }

    .column-item {
        font-size: 0.9em;
        margin-left: 11px;
        margin-right: 11px;
    }
}

div.overwrite-text {
    float: left;
    width: 663px;
}