.sign-in-form {
    &.message-box {
        position: relative;
        margin: 0 auto;
        top: 20px;
        left: 0;
    }
}

#sign-in {
    margin-top: 205px;
    border: 1px solid #76c0fa;
    .shadow(10px, 13px, 3px, 3px, rgba(0, 0, 0, 0.12));
    .border-radius(5px);
    .trans(#ffffff, 0.8);
}

#sign-in h1{
    margin: 0px;
    font-size: 16px;
    font-style: italic;
    color: #00abff;
}

.sign-in-logo {
    margin: 18px 0 0 30px;
    float: left;
}

#sign-in-slogan {
    color: @slogan-color;
    font-style: italic;
    font-weight: bold;
    font-size: 18px;
    float: left;
    margin: 20px 0 0 40px;
}

#sign-in-info {
    background-image: url("../images/signin_info_bg.jpg");
    width: 440px;
    height: 173px;
    border-top: solid 1px #25b5ff;
    margin: 13px 0 0 0;
}

#sign-in-info-right {
    color: #007cd2;
    float: right;
    width: 176px;
    margin: 17px 0 0 0;
}

#sign-in-info-left {
    color: #007cd2;
    float: left;
    width: 200px;
    margin: 18px 0 0 20px;
}

#sign-in-info-left-bottom {
    color: #939393;
    margin: 30px 0 0 ;
    width: 200px;
    a {
        color: #19b3ff;
        &:hover {
            color: #19b3ff;
            text-decoration: underline;
        }
        &:visited {
            color: #19b3ff;
        }
    }
}

#sign-in-form {
    margin: 20px 0 16px 0;
    label {
        color: #3b3b3b;
        font-weight: bold;
        font-size: 13px;
    }
    #sign-in-form-key {
        background-image: url("../images/klic_32x32.png");
        background-position: center;
        width: 34px;
        height: 32px;
        border-color: #8fc5e3;
        margin-top: 12px;
    }
    .sign-in-form-submit {
        background-image: url("../images/tlacitko_90x32.png");
        width: 90px;
        height: 32px;
        color: #0d87da;
        font-weight: bold;
        font-size: 13px;
        border-color: #8fc5e3;
        margin-top: 12px;
    }

    .sign-in-button-change{
        background-image: url("../images/tlacitko_90x32.png");
        width: 90px;
        height: 32px;
        color: #0d87da;
        font-weight: bold;
        font-size: 13px;
        border-color: #8fc5e3;

        &.top_1{
            margin-top: 12px;
        }

        &.top_2{
            margin-top: 24px;
        }

        &.top_3{
            margin-top: 36px;
        }

        &.top_4{
            margin-top: 60px;
        }
    }

    .change-pass {
        font-weight: bold;
        color: #056EC7;
        line-height: 40px;
        padding-left: 5px;
        &:hover {
            text-decoration: underline;
        }
    }
}