
.datastorage-explorer {

    .head {

        .head-uploadButton {
            overflow: hidden;

            input {
                opacity: 0;
                height: 100%;
                padding: 0;
                margin: 0;
                float: right;
                cursor: pointer;
            }
        }

    }

}