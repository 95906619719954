/* Variables
================================================== */

    @pg-i-color: rgba(100, 150, 255, 0.25);
    @pg-highlight-color: rgb(241,2,130);


    @pg-toggle-width: 210px;

    @-webkit-keyframes pg-pulse {.pg-pulse-frames;}
    @-moz-keyframes pg-pulse {.pg-pulse-frames;}
    @-ms-keyframes pg-pulse {.pg-pulse-frames;}
    @-o-keyframes pg-pulse {.pg-pulse-frames;}
    @keyframes pg-pulse {.pg-pulse-frames;}

    .pg-pulse-frames {
        0% {
            .pg-transform(0, 1, 0, 0);
            opacity: 0.8;
        }

        100% {
            .pg-transform(0, 1.3, 0, 0);
            .pg-rounded-corners(20px);
            opacity: 0;
        }
    }

/* Mixins
================================================== */

    .pg-rounded-corners (@radius: 5px) {
        -webkit-border-radius: @radius;
        -moz-border-radius: @radius;
        -o-border-radius: @radius;
        border-radius: @radius;
    }

    .pg-animation (@name: pg-pulse, @duration: 1s, @iterations: 4, @direction: normal, @ease: ease-out) {
        -webkit-animation-name: @name;
        -webkit-animation-duration: @duration;
        -webkit-animation-iteration-count: @iterations;
        -webkit-animation-direction: @direction;
        -webkit-animation-timing-function: @ease;

        -moz-animation-name: @name;
        -moz-animation-duration: @duration;
        -moz-animation-iteration-count: @iterations;
        -moz-animation-direction: @direction;
        -moz-animation-timing-function: @ease;

        -o-animation-name: @name;
        -o-animation-duration: @duration;
        -o-animation-iteration-count: @iterations;
        -o-animation-direction: @direction;
        -o-animation-timing-function: @ease;

        -ms-animation-name: @name;
        -ms-animation-duration: @duration;
        -ms-animation-iteration-count: @iterations;
        -ms-animation-direction: @direction;
        -ms-animation-timing-function: @ease;

        animation-name: @name;
        animation-duration: @duration;
        animation-iteration-count: @iterations;
        animation-direction: @direction;
        animation-timing-function: @ease;
    }

    .pg-transition (@prop: all, @time: 1s, @ease: linear) {
        -webkit-transition: @prop @time @ease;
        //-webkit-transform: translateZ(0);   /* Prevents weird font aliasing issue in Webkit */
        -moz-transition: @prop @time @ease;
        -o-transition: @prop @time @ease;
        -ms-transition: @prop @time @ease;
        transition: @prop @time @ease;
    }

    .pg-icon-replacement (@imgtouse: "pageguide.png", @height: 20px, @width: 20px, @bgposition: 0 0) {
        background: transparent url("@{imgtouse}") @bgposition no-repeat;
        display: block;
        height: @height;
        overflow: hidden;
        text-indent: -999px;
        width: @width;
    }

    .pg-box-shadow (@x: 0, @y: 0, @blur: 5px, @spread: 0, @color: rgba(0, 0, 0, 0.5)) {
        -webkit-box-shadow: @arguments;
        -moz-box-shadow: @arguments;
        -o-box-shadow: @arguments;
        box-shadow: @arguments;
    }

    .pg-opacity(@op:100) {
        filter:alpha(opacity=@op);
        -moz-opacity:@op/100;
        -khtml-opacity:@op/100;
        opacity:@op/100;
    }

    .pg-transform (@rotate: 90deg, @scale: 1, @skew: 1deg, @translate: 10px) {
        -webkit-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
        -moz-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
        -o-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
        -ms-transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
        transform: rotate(@rotate) scale(@scale) skew(@skew) translate(@translate);
    }

    .pg-border-box (){
        -moz-box-sizing: border-box;
        -webkit-box-sizing: border-box;
        box-sizing: border-box;
    }

    .pg-disable-select {
        -webkit-touch-callout: none;
        -webkit-user-select: none;
        -khtml-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    .pg-enable-select {
        -webkit-touch-callout: text;
        -webkit-user-select: text;
        -khtml-user-select: text;
        -moz-user-select: text;
        -ms-user-select: text;
        user-select: text;
    }

/* Page Guide
================================================== */
    #pageGuideWrapper {
        .pg-border-box;
        color: #000;
        font-size:13px;
        font-family: 'Helvetica', 'Arial', sans-serif;

        #pageGuideToggle {
            .pg-border-box;
            background-color: rgba(0, 0, 0, 0.1);
            .pg-rounded-corners(3px 0 0 3px);
            clear: both;
            color: #444;
            cursor: pointer;
            display: block;
            float: right;
            font-size: 12px;
            height: 60px;
            line-height: 25px;
            padding: 34px 6px 6px 8px;
            position: fixed;
            text-align: left;
            text-decoration: none;
            text-shadow: 0 1px rgba(255, 255, 255, 0.5);
            top: 130px;
            right: -@pg-toggle-width;
            width: @pg-toggle-width;
            .pg-transition(all, 0.2s, ease-in);

            &:before {
                content: "i";
                position: absolute;
                left: 23px;
                top: 6px;
                height: 25px;
                width: 25px;
                font: 20px/26px "Palatino Linotype", "Book Antiqua", Palatino, serif;
                font-weight: bold;
                text-align: center;
                text-shadow: 0 -1px rgba(0, 0, 0, 0.3);
                color: #fff;
                background-color: @pg-i-color;
                border: 3px solid rgba(235, 235, 235, 0.95);
                border-radius: 15px;
                .pg-box-shadow(0, -1px, 0, 0, rgba(0, 0, 0, 0.3));
            }

            div {
                .pg-border-box;
                border-left: 1px solid rgba(255, 255, 255, 0.8);
                display: block;
                height: 48px;
                width: 135px;
                line-height: 16px;
                overflow: hidden;
                padding: 0 0 0 6px;
                position: absolute;
                right: 0;
                top: 6px;

                span {
                    display: block;
                    margin: 0 0 0 -118px;
                    width: 124px;
                    .pg-transition(all, 0.2s, ease-in);
                }
            }

            a.pageguide-close {
                bottom: -25px;
                color: #444;
                opacity: 0;
                position: absolute;
                right: 5px;
                text-decoration: none;
                text-shadow: none;

                &:hover {
                    color: darken(@pg-highlight-color, 10%);
                }
            }
        }

        .pageguide-guide {
            list-style: none;
            margin: 0 0 0 0;
            padding: 0 0 0 0;

            li {
                background-color: desaturate(@pg-highlight-color, 100%);
                .pg-box-shadow(2px 2px 4px rgba(0, 0, 0, .15));
                .pg-transition(~"min-height, max-height", 0.1s, ease-in-out);
                .pg-transition(opacity, 0.5s, ease-out);
                opacity: 0;
                cursor: pointer;
                display: block;
                height: 46px;
                line-height: 48px;
                min-height: 0px;
                max-height: 0px;
                position: absolute;
                text-align: center;
                width: 50px;
                z-index: 1000;
                .pg-rounded-corners(3px);

                &.expanded {
                    opacity: 0.5;
                    height: 46px;
                    min-height: 15px;
                    max-height: 46px;

                    &:after {
                        display: block;
                    }
                }

                ins {
                    background: none;
                    color: #fff;
                    display: block;
                    font-size: 25px;
                    text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
                    overflow: hidden;
                    text-decoration: none;
                    width: 50px;
                }

                div {
                    display: none;
                }

                &:after {
                    content: " ";
                    display: none;
                    height: 0;
                    position: absolute;
                    width: 0;
                    border-top: none;
                    border-right: none;
                    border-bottom: none;
                    border-left: none;
                    top: auto;
                    left: auto;
                    right: auto;
                }

                &.pageguide-top {
                    .pg-rounded-corners(3px 3px 3px 0);
                    &:after {
                        border-top:6px solid desaturate(@pg-highlight-color, 100%);
                        border-right:6px solid transparent;
                        bottom: -4px;
                        left: 0;
                    }
                }

                &.pageguide-bottom {
                    .pg-rounded-corners(0 3px 3px 3px);
                    &:after {
                        border-bottom:6px solid desaturate(@pg-highlight-color, 100%);
                        border-right:6px solid transparent;
                        left: 0;
                        top: -4px;
                    }
                }

                &.pageguide-right {
                    .pg-rounded-corners(0 3px 3px 3px);
                    &:after {
                        border-top:6px solid desaturate(@pg-highlight-color, 100%);
                        border-left:6px solid transparent;
                        left: -4px;
                        top: 0;
                    }
                }

                &.pageguide-left {
                    .pg-rounded-corners(3px 0 3px 3px);
                    &:after {
                        border-top:6px solid desaturate(@pg-highlight-color, 100%);
                        border-right:6px solid transparent;
                        right: -4px;
                        top: 0;
                    }
                }

                &:hover {
                    background-color: fade(@pg-highlight-color, 95%);

                    &.pageguide-bottom:after {
                        border-bottom:6px solid fade(@pg-highlight-color, 95%);
                    }

                    &.pageguide-right,
                    &.pageguide-left,
                    &.pageguide-top {
                        &:after {
                            border-top:6px solid fade(@pg-highlight-color, 95%);
                        }
                    }
                }

                &.pageguide-active {
                    opacity: 1;
                    background-color: fade(@pg-highlight-color, 95%);
                    .pg-box-shadow(2px 2px6px rgba(0, 0, 0, .15));

                    &.pageguide-top:after {
                        border-top: 8px solid fade(@pg-highlight-color, 95%);
                        border-right: 8px solid transparent;
                        bottom: -8px;
                    }

                    &.pageguide-bottom:after {
                        border-bottom: 8px solid fade(@pg-highlight-color, 95%);
                        border-right: 8px solid transparent;
                        top: -8px;
                    }

                    &.pageguide-right:after {
                        border-top: 8px solid fade(@pg-highlight-color, 95%);
                        border-left: 8px solid transparent;
                        left: -8px;
                    }

                    &.pageguide-left:after {
                        border-top: 8px solid fade(@pg-highlight-color, 95%);
                        border-right: 8px solid transparent;
                        right: -8px;
                    }
                }
            }
        }

        #pageGuideMessage {
            .pg-disable-select();
            .pg-rounded-corners(5px 5px 0 0);
            background-color: rgba(0, 0, 0, 0.85);
            .pg-box-shadow(0, 0, 9px, 4px, rgba(0, 0, 0, 0.4));
            bottom: 0;
            display: none;
            font-size: 16px;
            height: 0;
            margin: 0 5% 0 5%;
            padding: 10px;
            position: fixed;
            width: 90%;
            z-index: 1001;

            span {
                .pg-disable-select();
                background-color: fade(@pg-highlight-color, 95%);
                .pg-rounded-corners(3px);
                color: #fff;
                display: block;
                font-size: 25px;
                height: 46px;
                left: 40px;
                line-height: 48px;
                text-shadow: 1px 1px 0px rgba(0, 0, 0, .5);
                overflow: hidden;
                position: absolute;
                text-align: center;
                top: 25px;
                width: 50px;
                cursor: pointer;
            }

            div.pageguide-content {
                .pg-enable-select();
                font-weight: 300;
                color: #fff;
                line-height: 22px;
                margin: 10px 90px 0 120px;
                position: relative;

                a {
                    color: #5aa5c8;

                    &:hover {
                        color: #fff;
                    }
                }
            }

            .pageguide-close {
                background-color: @pg-highlight-color;
                .pg-rounded-corners(0 0 3px 3px);
                text-shadow: 1px 1px 0px rgba(0, 0, 0, .25);
                color: #fff;
                display: block;
                font-size: 12px;
                height: 24px;
                line-height: 26px;
                overflow: hidden;
                position: absolute;
                right: 50px;
                text-align: center;
                text-decoration: none;
                top: 0;
                width: 50px;
                cursor: pointer;
            }

            .pageguide-back,
            .pageguide-fwd {
                .pg-disable-select();
                .pg-icon-replacement("../images/pageguide/pageguide_arrow.png", 30px, 30px, 10px 4px);
                position: absolute;
                top: 34px;
                left: 10px;
                cursor: pointer;
            }

            .pageguide-back {
                .pg-transform(-180deg, 1, 0, 0);
            }

            .pageguide-fwd {
                left: 90px;
            }
        }
    }

    .pageguide-ready {

        #pageGuideWrapper {

            #pageGuideToggle {
                &.pageguide-toggle-close {
                    right: -135px;
                }

                &.pageguide-toggle-open {
                    right: 0;
                }

                &:hover {
                    background-color: rgba(0, 0, 0, 0.2);
                    right: 0 !important;

                    div span {
                        margin: 0;
                    }
                }
            }
        }
    }

    .pageguide-open {

        #pageGuideWrapper {

            #pageGuideToggle {
                .pg-box-shadow(2px, 2px, 5px, 0, rgba(0, 0, 0, 0.25));
                background-color: fade(@pg-highlight-color, 90%);
                color: #fff;
                text-shadow: 0 1px rgba(0, 0, 0, 0.25);
                right: 0 !important;

                a {
                    opacity: 100;
                }

                div {
                  border-left: 1px solid rgba(255, 255, 255, .15);
                }

                div span {
                    margin: 0;
                }

                &:before {
                    background-color: lighten(@pg-highlight-color, 10%);
                }

                &:hover {
                    background-color: lighten(@pg-highlight-color, 20%);

                    div span {
                        margin: 0;
                    }
                }
            }
        }

        #pageGuideShadow {
            pointer-events: none;
        }

        .pageguide-shadow {
            display: none;
            opacity: 0;
            position: absolute;
            top: 0;
            left: 0;
            background-color: fade(@pg-highlight-color, 15%);
            .pg-box-shadow(0, 0, 1px, 1px, fade(@pg-highlight-color, 35%));
            .pg-rounded-corners(3px);
            /*.pg-transition(all, 0.5s, ease-out);*/

            &.pageguide-shadow-active {
                display: block;
                opacity: 100;

                position: absolute;
                top: 0;
                left: 0;
                padding: 0;
                margin: 0;

                pointer-events: none;

                .pg-box-shadow(0, 0, 1px, 1px, fade(@pg-highlight-color, 35%));

                .pageguide-shadow-pulse {
                    opacity: 0;
                    display: none;
                    position: absolute;
                    top: 0;
                    left: 0;
                    height: inherit;
                    width: inherit;
                    padding: inherit;
                    background-color: fade(@pg-highlight-color, 5%);
                    z-index: 500;
                    pointer-events: none;
                    .pg-box-shadow(0, 0, 5px, 1px, @pg-highlight-color);
                    .pg-animation(pg-pulse, 0.7s, 1, normal, ease-out);
                }
            }
        }
    }
