/* Variables
================================================== */

    @pg-i-color-active: rgba(100, 150, 255, 1);

/* Page Guide
================================================== */
    #pageGuideWrapper {

        #pageGuideToggle {

            &.active:before {
                background-color: @pg-i-color-active;
            }

        }
        
        #pageGuideMessage {
            font-size: 12px;
            height: auto;
            
            div.pageguide-content {
                line-height: 18px;
            }
        }

    }