/**
 * Styly formuláře
 * @author Petr Fotijev
*/

.list-filter {
    .filter-changed {
        background-color: pink;
        background-image: none;
    }
}

.customer-top-btns{
    float: right;
    margin-right: 20px;
}

.option-group {
    .trans(#FFFFFF, 0.9);
    padding: 15px 0 15px 20px;
    background-image: url("../images/option_group_bg.png");
    background-position: bottom left;
    background-repeat: repeat-x;
    width: 938px;
    border-right: 1px solid #BBE4FF;
    border-left: 1px solid #BBE4FF;

    .list-filter {
        width: 918px;
        background-image: url("../images/vypis_filtr.png");
        background-repeat: repeat-x;
        background-color: #8ECFF3;
        padding: 0 0 10px 0;
        color: white;
        position: relative;

        .form-submit {
            position: absolute;
            right: 50px;
            top: -10px;
            background-image: url("../images/tlacitko_filtr_32x90.png");
            background-position: left;
            width: 90px;
            height: 32px;
            color: #FFFFFF;
            font-weight: bold;
            font-size: 13px;
            border-color: #0B7EE0;
            padding: 0;
        }

        .list-filter-dot {
            margin: 10px 0 0 15px;
            float: left;
            background-image: url("../images/ikony_12x12.png");
            border: 1px solid #70CFE8;
            .border-radius(3px);
            .icon(12, 1, 1);
        }

        .list-filter-name {
            font-weight: bold;
            color: #FFFFFF;
            float: left;
            margin: 8px 0 0 12px;
        }

        .list-filter-switch {
            color: #88CAFF;
            float: left;
            margin: 8px 0 0 12px;
            a {
                color: #88CAFF;
                &.active, &:hover {
                    color: #FFFFFF;
                }
                span {
                    color: #88CAFF;
                }
            }
        }

        .list-filter-count {
            float: right;
            color: #FFFFFF;
            padding: 9px;
        }
    }

    .list-paging {
        width: 918px !important;
        color: #FFFFFF;
        height: 28px;
        line-height: 28px;
        background-image: url("../images/vypis_strankovani_bg.png");
        background-repeat: repeat-x;
        text-align: center;

        a {
            color: #FFFFFF;
            &:hover {
                color: #FFFFFF;
                text-decoration: underline;
            }
        }

        .list-prev {
            margin-right: 25px;
        }

        .list-next {
            margin: 0 25px;
        }

        input {
            width: 43px;
            height: 14px;
        }

        input[type=submit] {
            width: 18px;
            height: 18px;
            background-image: url("../images/vypis_strankovani_tlacitko.png");
            background-repeat: no-repeat;
            border: none;
        }

        form {
            display: inline-block;
        }
    }

    .list-paging-pages {
        display: inline-block;
    }

    .list-paging-icons {
        float: right;
        height: 28px;
    }
    #list-paging-add {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-print {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-wizard {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-refresh {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-increase {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-discount {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-send {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-link {
        margin: 1px 5px 0 0;
        display: inline-block;
    }
    #list-paging-calculator {
        margin: 1px 5px 0 0;
        display: inline-block;
    }

    #list-paging-serial-number{
        margin: 1px 5px 0 0;
        display: inline-block;
    }

    .contracts-top-btns {
        //position: absolute;
        //right: 44px;
        float: right;
        margin-right: 10px;
        &.last {
            margin-right: 20px;
        }
    }
}

.option-group h3 {
    color: #0071C6;
    margin: 0;
    padding: 0px 15px 10px 0px;
    text-transform: uppercase;

    .buttons {
        float: right;
        margin-right: 5px;
        a {
            float: left;
            margin-right: 5px;
        }
    }
}

.option-group h4 {
    color: #0071C6;
    margin: 0;
    padding: 0px 15px 10px 0px;
}

.pair-margin-bottom-1 {
    margin-bottom: @form-items-height+4 !important;
}

.pair-margin-bottom-2 {
    margin-bottom: 2*(@form-items-height+4) !important;
}

.pair-margin-bottom-3 {
    margin-bottom: 3*(@form-items-height+4) !important;
}

.pair-margin-bottom-4 {
    margin-bottom: 4*(@form-items-height+4) !important;
}

.pair-margin-bottom-5 {
    margin-bottom: 5*(@form-items-height+4) !important;
}

.pair-margin-top-1 {
    margin-top: @form-items-height+4 !important;
}

.pair-margin-top-2 {
    margin-top: 2*(@form-items-height+4) !important;
}

.pair-margin-top-3 {
    margin-top: 3*(@form-items-height+4) !important;
}

.pair-margin-top-4 {
    margin-top: 4*(@form-items-height+4) !important;
}

.pair-margin-top-5 {
    margin-top: 5*(@form-items-height+4) !important;
}

.pair-margin-top--1 {
    margin-top: -1*(@form-items-height+4) !important;
}

.pair-margin-top--2 {
    margin-top: -2*(@form-items-height+4) !important;
}

.pair-margin-top--3 {
    margin-top: -3*(@form-items-height+4) !important;
}

.pair-margin-top--4 {
    margin-top: -4*(@form-items-height+4) !important;
}

.pair-margin-top--5 {
    margin-top: -5*(@form-items-height+4) !important;
}

input.right_1 {
    padding-right: 22px;
}

input.right_2 {
    padding-right: 42px;
}

input.right_3 {
    padding-right: 62px;
}

input.left_1 {
    padding-left: 22px;
}

input.left_2 {
    padding-left: 42px;
}

input.left_3 {
    padding-left: 62px;
}

.input-right_1 {
    border-left: 1px solid #7E929E;
    background: white;
    position: absolute;
    cursor: pointer;
    right: 6px;
    margin-top: 1px;
    .border-radius-right(3px);
}

.input-right_2 {
    border-left: 1px solid #7E929E;
    background: white;
    position: absolute;
    cursor: pointer;
    right: 25px;
    margin-top: 1px;
    .border-radius-top-right(2px);
    .border-radius-bottom-right(2px);
}

.input-right_3 {
    border-left: 1px solid #7E929E;
    background: white;
    position: absolute;
    cursor: pointer;
    right: 44px;
    margin-top: 1px;
    .border-radius-top-right(2px);
    .border-radius-bottom-right(2px);
}

.input-left_1 {
    border-right: 1px solid #7E929E;
    background: white;
    position: absolute;
    cursor: pointer;
    left: 6px;
    margin-top: 1px;
    .border-radius-right(3px);
}

.input-left_2 {
    border-right: 1px solid #7E929E;
    background: white;
    position: absolute;
    cursor: pointer;
    left: 25px;
    margin-top: 1px;
    .border-radius-right(3px);
}

.input-left_3 {
    border-right: 1px solid #7E929E;
    background: white;
    position: absolute;
    cursor: pointer;
    left: 44px;
    margin-top: 1px;
    .border-radius-right(3px);
}

.input-right_1.disabled,
.input-right_2.disabled,
.input-right_3.disabled,
.input-left_1.disabled,
.input-left_2.disabled,
.input-left_3.disabled {
    cursor: auto;
}

.border-bottom {
    border-bottom: 1px solid black;
}

.border-top {
    border-top: 1px solid black;
}

/*TODO overflow aby pracoval vsude*/
.scrolling-x {
    overflow-x: auto;
    overflow: -moz-scrollbars-horizontal;
}

.scrolling-y {
    overflow-y: auto;
    overflow: -moz-scrollbars-vertical;
}

.submit {
    height: 20px;
    padding-left: 10px;
    padding-right: 10px;
    background-image: url("../images/form_submit_bg.png");
    background-position: left top;
    background-repeat: repeat-x;
    border: 1px solid #0F85D6;

    &:disabled {
        background-image: url("../images/form_submit_bg_disabled.png");
    }

    &.save, &.add, &.copy, &.import {
        padding-left: 28px;
        background-image: url("../images/form_submit_save.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled {
            background-image: url("../images/form_submit_save_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.open {
        padding-left: 28px;
        background-image: url("../images/form_submit_next.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled {
            background-image: url("../images/form_submit_next_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.back {
        padding-left: 28px;
        background-image: url("../images/form_submit_back.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled {
            background-image: url("../images/form_submit_back_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.next {
        padding-left: 28px;
        background-image: url("../images/form_submit_next.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled {
            background-image: url("../images/form_submit_next_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.play {
        padding-left: 28px;
        background-image: url("../images/form_submit_play.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled {
            background-image: url("../images/form_submit_play_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.delete {
        padding-left: 28px;
        background-image: url("../images/form_submit_delete.png"), url("../images/form_submit_bg.png");
        background-position: 7px center, left top;
        background-repeat: no-repeat, repeat-x;
        &:disabled {
            background-image: url("../images/form_submit_delete_disabled.png"), url("../images/form_submit_bg_disabled.png");
        }
    }

    &.export, &.import {
        width: 100px;
    }
}

.ui-multiselect {
    margin: 0 5px 0 5px;
}

.easyform .pair {
    display: block;
    float: none;
    clear: both;
}

input, div.input {
    padding: 0 5px 0 3px;

    &.input_control_number {
        text-align: right;
    }

    &.centered {
        text-align: center;
    }

    &.align-right {
        text-align: right;
    }
}

.unit {
    position: absolute;
    right: 6px;
    margin-top: 1px;
    height: 18px;
    padding: 0 2px;
    background: url("../images/input_bg.png") repeat-x;
    .border-radius-right(3px);
    color: #505050;
}

.selection {

    .control {
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        cursor: pointer;
    }

    .selection-container {

        .selection-container-picker {
            position: absolute;
            z-index: 1;

            .state-selection-table {
                border-collapse: collapse;
                margin: 0 0 0.4em;
                width: 100%;
                td {
                    text-align: center;
                    cursor: pointer;

                    div.icon-16 {
                        margin: 0 auto;
                    }
                }
            }
        }
    }
}

.fileinput {

    float: left;
    display: inline;
    margin: 0px;
    padding: 0px;

    .fileinput-input {
        background-image: url("../images/input_bg.png");
        background-repeat: repeat-x;
        border: 1px solid #7E929E;
        border-radius: 3px 3px 3px 3px;
        font-family: Calibri, Calib, Arial, Helvetica, sans-serif;
        height: 18px;
        line-height: 18px;
        margin: 0px;
        padding: 0 5px 0 3px;
        position: relative;

        .fileinput-input-text {
            height: 18px;
            margin-right: 36px;
            vertical-align: middle;
            overflow: hidden;
            white-space: nowrap;
            .fileinput-input-text-content {

            }
        }

        .ico_right_1 {
            position: absolute;
            right: 0px;
            border-bottom-right-radius: 3px;
            border-left: 1px solid #7E929E;
            border-top-right-radius: 3px;
            cursor: pointer;
            width: 18px;

            overflow: hidden;
            input {
                opacity: 0;
                height: 100%;
                padding: 0;
                margin: 0;
                float: right;
            }

        }

        .ico_right_2 {
            position: absolute;
            right: 19px;
            border-left: 1px solid #7E929E;
            cursor: pointer;
        }

        &.disabled {
            background-image: url("../images/input_bg_disabled.png");
            color: #AAAAAA;
        }

    }

}

.configurator_wizards_cards_dialog {
    .option-group {
        h3 {
            display: inline-block;
            font-size: 12px;
            text-transform: capitalize;
        }
    }
    .add {
        .condition, .selection_criterion, .parameter {
            .remove, .search {
                margin-left: 15px;
                margin-bottom: 4px;
            }
        }
    }
}

.formulainput, .formulainvalidinput {
    float: left;
    display: inline;

    .formulainput-container {
        clear: both;
    }

    .formulainput-input {
        display: inline;
        background-image: url("../images/input_bg.png");
        background-repeat: repeat-x;
        background-color: white;
        border: 1px solid #7E929E;
        border-radius: 3px 3px 3px 3px;
        font-family: Calibri, Calib, Arial, Helvetica, sans-serif;
        min-height: 18px;
        height: auto;
        position: relative;
        line-height: 16px;
        font-size: 12px;

        &.ico_1 {
            padding: 0 21px 0 4px;
        }
        &.ico_2 {
            padding: 0 41px 0 4px;
        }
        &.ico_3 {
            padding: 0 61px 0 4px;
        }

        input:disabled {
            background-image: none;
        }

        input.param {
            text-align: center;
            background-color: #F6F6F6;
            border: 1px solid #CCCCCC;
            .border-radius(3px);
            height: 14px;
            line-height: 14px;
            margin: 1px 1px 0 1px;
            position: relative;
            color: #3C3C3C;
            padding: 0px 4px;
            font-size: 10px;

            &.hover {
                border: 1px solid #888888;
            }
        }

    }

    .formulainput-params {
        clear: both;
        margin-bottom: 2px;
        //padding: 10px;
        button {
            text-align: center;
            background-color: #F6F6F6;
            border: 1px solid #CCCCCC;
            .border-radius(3px);
            height: 16px;
            margin: 0px 2px 1px 0px;
            float: left;
            color: #3C3C3C;
            //padding: 5px;
            font-size: 11px;
            //width: 100%;
            opacity: 0.5;

            &:hover {
                border: 1px solid #888888;
                opacity: 1;
            }
        }

        span {
            display: block;
            margin: 0px;
        }
    }
    .input-right, .input-right-1 {
        border-bottom-right-radius: 3px;
        border-left: 1px solid #7E929E;
        border-bottom: 1px solid #7E929E;
        border-top-right-radius: 3px;
        cursor: pointer;
        margin-top: 1px;
        position: absolute;
    }
    .input-right {
        right: 6px;
    }
    .input-right-1 {
        right: 25px;
    }

    .formula-variables-container {
        .formula-variables-container-picker {
            display: none;
        }
    }
}

#variabless {
    .formula-variables-container-picker {
        display: none;
        z-index: 1;
        position: absolute;
        min-width: 170px;
        max-width: 400px;
        .variables-content {
            margin: 5px 10px;
            overflow: hidden;
            button.btn-var {
                background-color: #F6F6F6;
                border: 1px solid #CCCCCC;
                .border-radius(3px);
                //height: 16px;
                margin: 0px 1px 1px 0px;
                color: #3C3C3C;
                font-size: 11px;
                width: 100%;
                padding: 0;

                &:hover {
                    border: 1px solid #888888;
                }

                div {
                    padding: 0 5px;
                    text-align: left;

                    .arrow {
                        float: right;
                        padding: 0;
                    }
                }
            }

            .div_selection {
                button.btn-var {
                    display: none;
                    color: #777777;
                    background-color: #FFFFFF;
                    border: 1px solid #EEEEEE;
                    margin-left: 10px;
                    width: 94%;
                    font-size: 10px;
                    &:hover {
                        border: 1px solid #CCCCCC;
                    }
                }
                button.btn-var.btn-main {
                    display: block;
                    color: #3C3C3C;
                    background-color: #F6F6F6;
                    border: 1px solid #CCCCCC;
                    margin-left: 0px;
                    width: 100%;
                    font-size: 11px;
                    &:hover {
                        border: 1px solid #888888;
                    }
                }
            }
        }
    }
}

.configurator_summarization {
    .demand_items, .order_items {
        .add, .remove {
            float: right;
        }
    }
}

.calculation_add_product_dialog, .chooser {
    overflow: hidden;
}

.calculation_add_product_dialog, .chooser {
    .scroll {
        max-height: 400px;
        overflow-y: auto;
    }
}

.editor_container {
    .editor_comment {
        color: #888888;
    }
}

.noclose .ui-dialog-titlebar-close {
    display: none;
}

.contracts-calculation {
    .submit {
        height: 18px;
        line-height: 18px;
        background-image: url("../images/form_submit_bg.png");
        background-position: left top;
        background-repeat: repeat-x;
        border: 1px solid #0F85D6;
        .border-radius(3px);

        &:hover {
            .highlight(@form-items-highlight);
        }

        &.disabled {
            background-image: url("../images/form_submit_bg_disabled.png");
            border: 1px solid #7E929E;
        }
    }
}

.border-bottom {
    border-bottom: 1px solid black;
}

.border-top {
    border-top: 1px solid black;
}