.clients-dialog-list {
    padding: 20px 20px 0 20px !important;

    .button {
        font-family: verdana;
        border-top: 1px solid @history-option-border;

        .item {

            .caption {
                padding: 10px 0 10px 0;

                .name-card {
                    font-size: 12px;
                    color: @history-name-card-color;
                }

                .option {
                    font-size: 12px;
                    color: @history-option-color;
                    padding: 0 10px;
                    line-height: 15px;

                    div {
                        clear: right;
                        color: @history-option-color;
                    }

                    div.temp {
                        clear: right;
                        color: @history-option-color-temp;
                    }

                    .value {
                        float: right;
                        max-width: 43%;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        white-space: nowrap;
                    }
                }
            }
        }
    }

    :first-child {
        border: 0;
    }
}