.notes {
    .nav-item-icon {
        cursor: pointer;
    }
}

.notes-dialog {
    textarea {
        width: 96%;
        height: 95%;
        margin: 2% 0 0 2%;
        border: 1px solid #EEEEEE;
    }
}