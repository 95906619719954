@media print {
    .filter-pdf{
        font-weight: bold;
        border: 1px dotted black;
        margin-bottom: 15px;
        
    }

    .filter-pdf .list-filter-name{
        width: 800px;
        float: left;
    }
}