
.product-pricelist {
    width: 1280px;
    margin-bottom: 4px;

    .checkbox {
        margin-right: 0;
        margin-top: 6px;
        width: 14px;
    }

    .width {
        width: 40px;
        font-size: 75%;
        margin-top: 4px;
    }
}


#matrix,
#prices-matrix {
    margin-top: 10px;
    width: 920px;

    .empty-column{
        height: 20px;
    }

    .m120{
        margin-left: 120px;
    }

    .cols-label{
        display: inline;
    }

    .matrix-container,
    .prices-matrix-container{
        padding-bottom: 15px;
        //display: inline;
    }

    td {
        text-align: right;
        vertical-align: top;
        padding-top: 5px;
        width: 120px;

        .matrix-btn{
            margin-right: 5px;
        }

        .item-legend{
            padding-top: 2px;
            height: 20px;
        }

        &.rows-label{
            text-align: left;
            vertical-align: bottom;
        }
        &.last_td{
            width: 120px;
        }
    }
}

.configurator-products-bulk-edit {
    .padding_0 {
        padding: 0px;
    }
}

.firms-score {
    #list {
        width: 918px;
        margin: 0;
        box-shadow: none;

        .list-sum {
            h2 {
                float:left;
                width: 400px;
            }
            .coeficient {
                color: #009dff;
                line-height: 25px;
            }
        }
    }
}

.services-prices,
.configurator-parameters,
.configurator-products-prices {
    a {
        &.submit {
            display: inline-block;
            border-radius: 3px;
            height: 18px;
            line-height: 20px;
            text-align: center;
        }
    }
}

.services-products {
    a {
        &.submit {
            display: inline-block;
            border-radius: 3px;
            height: 18px;
            line-height: 20px;
            text-align: center;
        }
    }
}