
/* Základní Grid (48 buněk po 20px) */
.container(48, 20px);

/**
 * Definice pomocných mixinů
*/

/* Vytvoření kontejneru o zadaném počtu sloupců */
.container(@cols, @colWidth: 20px) {
        .container_@{cols} {
        margin-left: auto;
        margin-right: auto;
        width: @colWidth * @cols;
        .grid(@cols, @colWidth);
    }
}

.grid(@i, @colWidth: 20px) when (@i > 0) {

    .grid_@{i} {
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 10px + ((@i - 1) * @colWidth);
    }

    /* pro pozicovani v menu */
    .menu-dlazdice-stred.grid_@{i} {
        width: 16px + ((@i - 2) * @colWidth);
        display: inline;
        margin-left: 0px;
        margin-right: 0px;
        float: left;
    }

    /* sirky prvku s ohranicenim formularem */
    input.grid_@{i} {
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: ((@i - 1) * @colWidth);
    }

    /* u textarea je treba odecist 2px z sirky kvuli borderu */
    textarea.grid_@{i} {
        width: 10px + ((@i - 1) * @colWidth) - 4px;
    }

    /* pro prvek fileinput */
    .fileinput-input.grid_@{i}, .formulainput-input.grid_@{i} {
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: ((@i - 1) * @colWidth);
    }

    /* sirky inputu s jednou vnitrni ikonkou */
    input.ico_1.grid_@{i} {
        color: #505050;
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 5px + ((@i - 1) * @colWidth)-22;
    }
    /* sirky inputu s jednou vnitrni ikonkou */
    div.ico_1.grid_@{i} {
        color: #505050;
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 5px + ((@i - 1) * @colWidth)-22;
    }
    /* sirky inputu s dvema vnitrnima ikonkama */
    input.ico_2.grid_@{i} {
        color: #505050;
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 5px + ((@i - 1) * @colWidth)-42;
    }
    /* sirky inputu s dvema vnitrnima ikonkama */
    div.ico_2.grid_@{i} {
        color: #505050;
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 5px + ((@i - 1) * @colWidth)-42;
    }
    /* sirky inputu se třema vnitrnima ikonkama */
    input.ico_3.grid_@{i} {
        color: #505050;
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 5px + ((@i - 1) * @colWidth)-62;
    }
    /* sirky inputu se třema vnitrnima ikonkama */
    div.ico_3.grid_@{i} {
        color: #505050;
        display: inline;
        float: left;
        position: relative;
        margin-left: 5px;
        margin-right: 5px;
        width: 5px + ((@i - 1) * @colWidth)-62;
    }

    /* sirky komponent formulare */
    .pair_@{i}, .block_@{i} {
        display: inline;
        float: left;
        position: relative;
        width: @colWidth * @i;
    }

    .alpha {
        margin-left: 0;
    }

    .omega {
        margin-right: 0;
    }

    .push_@{i} {
        left: @colWidth * @i;
        position: relative;
    }

    .pull_@{i} {
        left: @colWidth * @i * -1;
        position: relative;
    }

    .prefix_@{i} {
        padding-left: @colWidth * @i;
    }

    .suffix_@{i} {
        padding-right: @colWidth * @i;
    }

    .labels label {
        line-height: 24px;
    }

    .grid(@i - 1);
}


/* `Clear Floated Elements
----------------------------------------------------------------------------------------------------*/
/* http://sonspring.com/journal/clearing-floats */
.clear {
    clear: both;
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}
/* http://www.yuiblog.com/blog/2010/09/27/clearfix-reloaded-overflowhidden-demystified */
.clearfix:before,
.clearfix:after {
    content: '\0020';
    display: block;
    overflow: hidden;
    visibility: hidden;
    width: 0;
    height: 0;
}

.pagebreak {
    page-break-before: always;
}

.activeRow {
    background-color: #82CFFD;
}