#infopanel {
    z-index: 100;
    position: absolute;
    top: 8px;
    left: 20px;
    width: 174px;
    border: 1px solid #545b65;
    overflow: hidden;
    .border-radius(4px);
    .shadowpanel();
}
#infopanel_help {
    z-index: 100;
    position: absolute;
    top: 8px;
    left: 720px;
    width: 174px;
    border: 1px solid #545b65;
    overflow: hidden;
    .border-radius(4px);
    .shadowpanel();
}
.infopanel-item {
    padding: 0px 0 0px 25px;
    color: white;
    font-size: 11px;
    text-transform: uppercase;
    background-image: url("../images/infopanel_bg.png");
    background-repeat: repeat-x;
    height: 24px;
}
.infopanel-disabled{
    color: #b0b0b0;
}
.infopanel-lastitem{
    padding: 0px 0 0px 25px;
    color: white;
    font-size: 11px;
    text-transform: uppercase;
    background-image: url("../images/infopanel_bg.png");
    background-repeat: repeat-x;
    height: 23px;
}
.infopanel-item:hover {
    background-image: url("../images/infopanel_active_bg.png");
}
.infopanel-item:hover .infopanel-item-info{
    border: 1px solid #29a0f7;
    color: white;
    background-image: url("../images/infopanel_active_info.png");
}
.infopanel-lastitem:hover {
    background-image: url("../images/infopanel_active_bg.png");
}
.infopanel-lastitem:hover .infopanel-item-info{
    border: 1px solid #29a0f7;
    color: white;
    background-image: url("../images/infopanel_active_info.png");
}
.infopanel-item-name {
    display: inline-block;
    line-height: 24px
}
.infopanel-item-info {
    border: 1px solid #434a54;
    padding: 0 5px 0 5px;
    margin: 5px 10px 0 0;
    color: #b0b0b0;
    background-image:  url("../images/infopanel_info.png");
    height: 14px;
    text-align: center;
    line-height: 13px;
    float: right;
    display: inline-block;
    .border-radius(6px);
}