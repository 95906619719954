#langpanel{
    z-index: 100;
    position: absolute;
    width: 110px;
    top: -10px;
    left: 850px;
    overflow: hidden;
    border: 1px solid #a6b6be;
    color: black;
    .border-radius(4px);
    .shadowpanel();
}
.langpanel-item{
    padding: 0px 0px 0px 0;
    font-size: 12px;
    line-height: 24px;
    background-image: url("../images/langpanel_item.png");
    background-repeat: repeat-x;
    height: 24px;
}
.langpanel-lastitem{
    padding: 0px 0px 0px 0;
    font-size: 12px;
    line-height: 24px;
    background-image: url("../images/langpanel_item.png");
    background-repeat: repeat-x;
    height: 23px;
}
.langpanel-item:hover{
    background-image: url("../images/langpanel_item_active.png");
}
.langpanel-lastitem:hover{
    background-image: url("../images/langpanel_item_active.png");
}
.langpanel-icon{
    float: left;
    margin: 4px 10px 0 10px;
}
.langpanel-name{
    float: left;
}