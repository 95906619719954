.datastorage-explorer {
    .head {
        background-image: url("../images/vypis_strankovani_bg.png");
        color: #FFFFFF;

        table {
            td {
                height: 28px;
                padding: 0 0 0 5px;
            }
        }
    }
}